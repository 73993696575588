<div class="container loader-container" [hidden]="!isLoading">
	<div class="row">
		<div class="col-12 text-center">
			<svg viewBox="0 0 222 244" version="1.1" class="loader mx-auto" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
				<defs></defs>
				<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<g id="StreetRadioLogo" fill="#fff">
						<g id="Group-7">
							<path d="M55.095,134.039 L164.813,134.039 C195.046,134.039 221.866,120.386 221.866,86.983 C222.597,54.556 195.046,42.121 164.813,42.121 L93.619,42.121 C81.672,42.121 75.333,37.488 75.333,29.687 C75.333,21.884 81.672,17.252 93.619,17.252 L216.257,17.252 L216.257,0.185 L87.767,0.185 C61.191,0.185 45.099,13.839 45.099,33.831 C45.099,56.506 66.067,65.283 87.767,65.283 L158.23,65.283 C171.639,65.283 183.342,69.672 183.342,83.082 C183.342,96.492 171.639,100.881 158.23,100.881 L46.075,100.881 C13.403,100.881 0.481,114.534 0.481,132.089 C0.481,148.181 11.453,157.69 25.594,165.736 L165.056,244 L216.257,244 L216.257,212.548 L53.632,139.404 C50.464,137.941 50.951,134.039 55.095,134.039" id="Fill-4"></path>
						</g>
					</g>
				</g>
			</svg>

		</div>
	</div>
</div>

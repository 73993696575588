import { createSelector } from '@ngrx/store';

import * as fromCMS from '@app/cms/store/reducers';
import * as fromPost from '@app/cms/store/reducers/post.reducers';
import { ResourceLoaderState } from '@app/cms/models/post.model';

export const { selectEntities: getPostEntities, selectAll: getAllPosts } = fromPost.adapter.getSelectors(
	fromCMS.getPostsState
);

export const getPostIds = (collection: string, page: number) =>
	createSelector(fromCMS.getPostsState, state => state.collections[collection].pages[page] || []);

export const getPostsPages = (collection: string) =>
	createSelector(fromCMS.getPostsState, state => state.collections[collection].pages);

export const getPostsFilter = (collection: string) =>
	createSelector(fromCMS.getPostsState, state => state.collections[collection].filter);

export const getPostsPagination = (collection: string) =>
	createSelector(
		fromCMS.getPostsState,
		state => (state.collections[collection] ? state.collections[collection].pagination : undefined)
	);

export const getPostsPagesLoaded = (collection: string) =>
	createSelector(fromCMS.getPostsState, state => Object.keys(state.collections[collection].pages).length);

export const getPosts = (collection: string, options: { allPages: boolean } = { allPages: true }) =>
	createSelector(
		getPostEntities,
		getPostsPagination(collection),
		getPostsPages(collection),
		(entities, pagination, pages) => {
			let ids = [];
			if (pagination) {
				ids = options.allPages
					? Object.values(pages).reduce((acc, val) => acc.concat(val), [])
					: pages[pagination.current_page];
			}
			// console.log(ids);
			return ids.map(id => entities[id]);
		}
	);

export const getPostsLoaderState = (collection: string) =>
	createSelector(fromCMS.getPostsState, state => state.collections[collection]._loaderState);

export const getPost = (slug: string) => createSelector(getPostEntities, posts => posts[`${slug}`]);

export const getPostLoaderState = (slug: string) =>
	createSelector(getPost(slug), post => (post ? post : fromPost.initialPostState)._loaderState);

export const arePostsLoaded = (collection: string) =>
	createSelector(getPostsLoaderState(collection), (_loaderState: ResourceLoaderState) => _loaderState.loaded);

export const arePostsLoading = (collection: string) =>
	createSelector(getPostsLoaderState(collection), (_loaderState: ResourceLoaderState) => _loaderState.loading);

export const arePostsLoadedOrLoading = (collection: string) =>
	createSelector(
		getPostsLoaderState(collection),
		(_loaderState: ResourceLoaderState) => _loaderState.loaded || _loaderState.loading
	);

export const arePostsFailed = (collection: string) =>
	createSelector(getPostsLoaderState(collection), (_loaderState: ResourceLoaderState) => _loaderState.failed);

export const isPostLoadedorLoading = (slug: string) =>
	createSelector(
		getPostLoaderState(slug),
		(_loaderState: ResourceLoaderState) => _loaderState.loaded || _loaderState.loading
	);

import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { PostsAPIService } from '@app/cms/services/post.service';
import * as PostActions from '@app/cms/store/actions/post.actions';
import { switchMap, mergeMap, catchError, map, first } from 'rxjs/operators';
import { getPostsFilter } from '@app/cms/store/selectors';
// import { tag } from 'rxjs-spy/operators/tag';
export class PostEffects {
    constructor(actions$, store$, postsAPIService) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.postsAPIService = postsAPIService;
        // @Effect()
        // setFilter$: Observable<Action> = this.actions$
        // 	.ofType(PostActions.POSTS_SET_FILTER).pipe(
        // 		tap(x => console.log('effect setFilter$ payload = ', x)),
        // 		map((action: PostActions.PostsSetFilterAction) => action.payload.collection),
        // 		mergeMap(collection => [
        // 			new PostActions.PostsLoadAction(collection),
        // 		]),
        // 	);
        this.loadPosts$ = this.actions$.pipe(ofType(PostActions.POSTS_LOAD), map((action) => action.payload), mergeMap(({ collection, page, count = null }) => this.store$.select(getPostsFilter(collection)).pipe(first(), map(filter => ({ collection, filter, page, count })))), mergeMap(({ collection, filter, page, count }) => {
            return this.postsAPIService.getPosts({ filter, page, count }).pipe(mergeMap(result => [new PostActions.PostsLoadSuccessAction(Object.assign({}, result, { collection }))]), catchError(error => {
                console.log('effect error', error);
                return of(new PostActions.PostsLoadFailureAction({ collection }));
            }));
        })
        // tag('effect-loadposts'),
        );
        this.loadPostDetails$ = this.actions$.pipe(ofType(PostActions.POST_LOAD), map((action) => action.payload), switchMap(({ slug, options }) => {
            return this.postsAPIService.getPost(slug, options).pipe(mergeMap(post => [new PostActions.PostLoadSuccessAction({ post })]), catchError(error => {
                console.log('effect error', error);
                return of(new PostActions.PostLoadFailureAction({ slug }));
            }));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], PostEffects.prototype, "loadPosts$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], PostEffects.prototype, "loadPostDetails$", void 0);

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { Settings } from '@app/cms';

@Injectable()
export class SettingsAPIService {
	private readonly endpoint = '/api/settings';

	constructor(private http: HttpClient) {}

	public getSettings(): Observable<Settings> {
		const endpoint = this.endpoint;
		// console.log(`XHR to ${endpoint} sent.`);
		return this.http.get<{ data: Settings }>(endpoint).pipe(
			// tap(response => console.log(`XHR to ${endpoint} finished.`, response)),
			pluck('data')
		);
	}
}

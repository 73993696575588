import { Component, OnInit } from '@angular/core';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { Broadcast, CMSService, Filter, PageNum, Pagination, Program } from '@app/cms';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BroadcastAPIService } from '@app/cms/services/broadcast.service';
import { KeyValue, Location } from '@angular/common';
import { ProgramAPIService } from '@app/cms/services/program.service';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'app-program',
	templateUrl: './program.component.html',
	styleUrls: ['./program.component.scss']
})
export class ProgramComponent implements OnInit {
	public filter: number = 0;
	public page: number = 0;

	public isLoading: boolean = true;

	public programs$: Observable<{ items: Partial<Program>[]; pagination: Pagination }>;

	public categoryForm: FormGroup;
	public paginationForm: FormGroup;

	public _categoryFormChanges$: Observable<string>;
	public _categoryChangesViaRouteData$: Observable<string>;
	public _categoryChangesViaRouteParam$: Observable<string>;
	public categoryChanges$: Observable<string>;

	public _paginationFormChanges$: Observable<string>;
	public _pageChange$: Observable<PageNum>;

	constructor(
		private _cms: CMSService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _programsApi: ProgramAPIService,
		private _location: Location
	) {
		this._pageChange$ = of(1);
	}

	ngOnInit() {
		this.categoryForm = new FormGroup({
			category: new FormControl('')
		});

		this.paginationForm = new FormGroup({
			page: new FormControl(1)
		});

		this._categoryFormChanges$ = this.categoryForm.get('category').valueChanges.pipe(
			// tap(v => console.log('offerForm duration changed:', v)),
			tap(category => {
				const rePathSegment = new RegExp('program/?[a-zA-Z0-9]*');
				const updatedURL = this._router.url.replace(rePathSegment, 'program/' + category);
				this._location.replaceState(updatedURL);
			})
		);

		this._categoryChangesViaRouteData$ = this._route.data.pipe(
			map(data => data['category']),
			tap(category => {
				// set the checkbox to reflect route
				if (category === 'rallye' || category === 'street' || category === '') {
					this.categoryForm.get('category').setValue(category, { emitEvent: false });
				} else {
					const updatedURL = this._router.url.replace('/' + category, '/');
					this._location.replaceState(updatedURL);
				}
			})
			// tap(x => console.log('offerType changed via route:', x)),
		);

		this._categoryChangesViaRouteParam$ = this._route.paramMap.pipe(
			filter(params => params.has('category')),
			map(params => params.get('category')),
			tap(category => {
				// set the checkbox to reflect route
				if (category === 'rallye' || category === 'street') {
					this.categoryForm.get('category').setValue(category, { emitEvent: false });
				} else {
					const updatedURL = this._router.url.replace('/' + category, '/');
					this._location.replaceState(updatedURL);
				}
			})
			// tap(x => console.log('offerType changed via route:', x)),
		);

		this.categoryChanges$ = merge(
			this._categoryFormChanges$,
			this._categoryChangesViaRouteData$,
			this._categoryChangesViaRouteParam$
		).pipe(
			// tap(x => console.log('offerType changed:', x)),
			shareReplay(1)
		);

		this._paginationFormChanges$ = this.paginationForm
			.get('page')
			.valueChanges.pipe
			// tap(v => console.log('offerForm duration changed:', v)),
			();

		this.programs$ = combineLatest(
			this.categoryChanges$.pipe(
				map(category => {
					return {
						filter: (category !== '' ? { category: [category] } : {}) as Filter,
						key: `category-${category}`
					};
				})
			),
			// this._pageChange$,
			this._paginationFormChanges$.pipe(
				map(page => {
					return page;
				})
			),
			(category, page) => {
				return {
					category,
					page
				};
			}
		).pipe(
			switchMap(({ category, page }) => {
				// @ts-ignore
				const pageNum: number = page;
				return this._programsApi.getProgramsByMonths({ filter: category.filter, page: pageNum });
				// return this._cms.getPosts(`posts-${category.key}-${pageNum}`, pageNum, postOptions);
			}),
			tap(programs => {
				if (programs.items) this.isLoading = false;
			})
		);

		setTimeout(() => {
			this.paginationForm.get('page').setValue(1);
		}, 0);
	}

	public onPageChange(page) {
		// this._pageChange$ = of(page);
		this.paginationForm.get('page').setValue(page as Number);
	}

	public reverseKeyOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
		return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
	}
}

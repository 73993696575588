import { Component, OnInit, Input } from '@angular/core';
import { PostState } from '@app/cms';

@Component({
	selector: 'app-small-post-image',
	templateUrl: './small-post-image.component.html',
	styleUrls: ['./small-post-image.component.scss']
})
export class SmallPostImageComponent implements OnInit {
	@Input()
	public post: PostState;

	constructor() {}

	ngOnInit() {}
}

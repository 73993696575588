<div class="row">
	<div class="col-md-12"><h3 class="date">{{ getMonthName(monthTitle) }}</h3></div>
</div>
<div class="row">
	<div class="col-md-9">
		<app-selected-post [post]="selectedPost"></app-selected-post>
	</div>
	<div class="col-md-3">
		<div class="row h-100">
			<div class="col-md-12 mb-2" *ngIf="sidePosts[0]">
				<app-small-post [post]="sidePosts[0]"></app-small-post>
			</div>
			<div class="col-md-12" *ngIf="sidePosts[1]">
				<app-small-post [post]="sidePosts[1]"> </app-small-post>
			</div>
		</div>
	</div>
</div>
<div class="row mt-4" *ngIf="imagePosts.length">
	<div class="col-md-3 col-6" *ngFor="let post of imagePosts">
		<app-small-post-image [post]="post"></app-small-post-image>
	</div>
</div>
<div class="row" *ngIf="posts.length">
	<div class="col-md-3 col-6" *ngFor="let post of posts">
		<app-small-post [post]="post"></app-small-post>
	</div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { PostState } from '@app/cms';

@Component({
	selector: 'app-text-block',
	templateUrl: './text-block.component.html',
	styleUrls: ['./text-block.component.scss']
})
export class TextBlockComponent implements OnInit {
	@Input()
	public content: any;

	constructor() {}

	ngOnInit() {}
}

import * as tslib_1 from "tslib";
import { Store } from '@ngrx/store';
import * as fromCMS from '../store';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DEFAULT_POST_COLLECTON, PAGE_NEXT, PAGE_PREV } from '@app/cms/models/post.model';
export const RELOAD_COLLECTION = true;
// @Injectable({
// 	providedIn: CMSModule
// })
export class CMSService {
    constructor(_store, _router) {
        this._store = _store;
        this._router = _router;
    }
    // POSTS
    _loadPosts(collection, page = PAGE_NEXT, count) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('CMS _loadPosts', collection, page);
            if (page === PAGE_NEXT || page === PAGE_PREV) {
                let current_page = 0;
                this._store
                    .select(fromCMS.getPostsPagination(collection))
                    .pipe(first())
                    .subscribe(pagination => {
                    if (typeof pagination !== 'undefined') {
                        current_page = pagination.current_page;
                    }
                });
                if (page === PAGE_NEXT)
                    page = current_page + 1;
                if (page === PAGE_PREV)
                    page = current_page - 1;
            }
            this._store.dispatch(new fromCMS.PostsLoadAction({ collection, page, count }));
        });
    }
    loadPosts(collection = DEFAULT_POST_COLLECTON, page = PAGE_NEXT) {
        // console.log('CMS loadPostCollection', collection, page);
        this._loadPosts(collection, page);
    }
    setPostsFilter(collection = DEFAULT_POST_COLLECTON, filter = {}, updateCollection = false) {
        // console.log('CMS setPostCollectionFilter', collection, filter, updateCollection);
        this._store.dispatch(new fromCMS.PostsSetFilterAction({ collection, filter }));
        if (updateCollection)
            this.loadPosts(collection, 1);
    }
    getPosts(collection = DEFAULT_POST_COLLECTON, page = PAGE_NEXT, options) {
        // console.log('CMS getPosts', collection, options);
        options = Object.assign({ loadPostsImmediately: false }, options);
        if (options.filter)
            this.setPostsFilter(collection, options.filter);
        if (options.loadPostsImmediately)
            this.loadPosts(collection, page);
        return this._store.select(fromCMS.getPosts(collection));
    }
    getPostsLoaderState(collection = DEFAULT_POST_COLLECTON) {
        return this._store.select(fromCMS.getPostsLoaderState(collection));
    }
    getPostsPagination(collection = DEFAULT_POST_COLLECTON) {
        return this._store.select(fromCMS.getPostsPagination(collection));
    }
    // POST
    getPost(slug, options = {}) {
        this._loadPost(slug, options);
        return this._store
            .select(fromCMS.getPost(slug))
            .pipe();
    }
    _loadPost(slug, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!(yield this._store
                .select(fromCMS.isPostLoadedorLoading(slug))
                .pipe(first())
                .toPromise())) {
                this._store.dispatch(new fromCMS.PostLoadAction({ slug, options }));
            }
        });
    }
}

<section class="bottom">
	<div class="container" *ngxLet="programs$ | async as programs">
		<div class="row">
			<div class="col-md-6"><h2>Program</h2></div>
			<div class="col-md-6 align-self-center text-md-right mb-3 mb-md-4" *ngIf="programs && programs.items && programs.items.length">
				<form [formGroup]="categoryForm">
					<div class="btn-group btn-group-toggle" ngbRadioGroup name="category-radio" formControlName="category">
						<label ngbButtonLabel class="btn btn-grey">
							<input ngbButton type="radio" [value]="''" /> ÖSSZES
						</label>
						<label ngbButtonLabel class="btn btn-grey">
							<input ngbButton type="radio" [value]="'rallye'" /> RALLYE
						</label>
						<label ngbButtonLabel class="btn btn-grey">
							<input ngbButton type="radio" [value]="'street'" /> STREET
						</label>
					</div>
				</form>
			</div>
		</div>

		<app-loader [isLoading]="true" *ngIf="isLoading"></app-loader>

		<ng-container *ngIf="programs && programs.items && programs.items.length">
			<ng-container *ngFor="let monthPosts of programs.items | keyvalue:reverseKeyOrder">
				<app-programs-month [programs]="monthPosts.value" [monthTitle]="monthPosts.key"></app-programs-month>
			</ng-container>
		</ng-container>

		<div class="row mt-5" *ngIf="programs && programs.items && !programs.items.length">
			<div class="col-12 col-md-5 mx-auto">
				Nincsen elérhető tartalom.
			</div>
		</div>

		<div class="row mt-5" *ngIf="programs && programs.items && programs.items.length">
			<div class="col-md-12">
				<ngb-pagination
					class="d-flex justify-content-center"
					[maxSize]="5"
					[rotate]="true"
					[collectionSize]="programs.pagination.total"
					[pageSize]="programs.pagination.per_page"
					[(page)]="programs.pagination.current_page"
					(pageChange)="onPageChange($event)"
				></ngb-pagination>
			</div>
		</div>
	</div>
</section>

<div class="row">
	<div class="col-md-12">
		<h3>{{ getMonthName(monthTitle) }}</h3>
	</div>
</div>
<div class="row">
	<div class="col-md-12" *ngFor="let program of programs">
		<app-program-card [program]="program"></app-program-card>
	</div>
</div>

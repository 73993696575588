import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { Filter, PageNum, Pagination, Post } from '@app/cms';
import { ActivatedRoute } from '@angular/router';
import { PostsAPIService } from '@app/cms/services/post.service';
import { BroadcastAPIService } from '@app/cms/services/broadcast.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Broadcast } from '@app/cms/models/broadcast.model';

@Component({
	selector: 'app-result',
	templateUrl: './result.component.html',
	styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
	public posts$: Observable<{ items: Partial<Post>[]; pagination: Pagination }>;

	public broadcasts$: Observable<{ items: Partial<Broadcast>[]; pagination: Pagination }>;

	public categoryForm: FormGroup;
	public paginationForm: FormGroup;
	public typeForm: FormGroup;

	public _categoryFormChanges$: Observable<string>;
	public _paginationFormChanges$: Observable<string>;
	public _typeFormChanges$: Observable<string>;
	public _pageChange$: Observable<PageNum>;

	public queryChanges$: Observable<string>;

	public isLoading: boolean = true;

	constructor(
		private _route: ActivatedRoute,
		private _postsApi: PostsAPIService,
		private _broadcastsApi: BroadcastAPIService
	) {}

	ngOnInit() {
		this.categoryForm = new FormGroup({
			category: new FormControl('')
		});

		this.paginationForm = new FormGroup({
			page: new FormControl(1)
		});

		this.typeForm = new FormGroup({
			type: new FormControl('post')
		});

		this._categoryFormChanges$ = this.categoryForm
			.get('category')
			.valueChanges.pipe
			// tap(v => console.log('offerForm duration changed:', v)),
			();

		this._paginationFormChanges$ = this.paginationForm
			.get('page')
			.valueChanges.pipe
			// tap(v => console.log('offerForm duration changed:', v)),
			();

		this._typeFormChanges$ = this.typeForm
			.get('type')
			.valueChanges.pipe
			// tap(v => console.log('offerForm duration changed:', v)),
			();

		this.queryChanges$ = this._route.paramMap.pipe(
			filter(params => params.has('query')),
			map(params => params.get('query'))
			// tap(x => console.log('offerType changed via route:', x)),
		);

		this.broadcasts$ = combineLatest(
			this._categoryFormChanges$.pipe(
				map(category => {
					return {
						filter: (category !== '' ? { category: [category] } : {}) as Filter,
						key: `category-${category}`
					};
				})
			),
			// this._pageChange$,
			this._paginationFormChanges$.pipe(
				map(page => {
					return page;
				})
			),

			this.queryChanges$.pipe(
				map(page => {
					return page;
				})
			),
			(category, page, query) => {
				return {
					category,
					page,
					query
				};
			}
		).pipe(
			switchMap(({ category, page, query }) => {
				// @ts-ignore
				const pageNum: number = page;
				category.filter.query = query;
				return this._broadcastsApi.getBroadcastsByMonths({ filter: category.filter, page: pageNum });
				// return this._cms.getPosts(`posts-${category.key}-${pageNum}`, pageNum, postOptions);
			}),
			tap(broadcasts => {
				if (broadcasts.items) this.isLoading = false;
			})
		);

		this.posts$ = combineLatest(
			this._categoryFormChanges$.pipe(
				map(category => {
					return {
						filter: (category !== '' ? { category: [category] } : {}) as Filter,
						key: `category-${category}`
					};
				})
			),
			// this._pageChange$,
			this._paginationFormChanges$.pipe(
				map(page => {
					return page;
				})
			),

			this.queryChanges$.pipe(
				map(page => {
					return page;
				})
			),
			(category, page, query) => {
				return {
					category,
					page,
					query
				};
			}
		).pipe(
			switchMap(({ category, page, query }) => {
				// @ts-ignore
				const pageNum: number = page;
				category.filter.query = query;
				return this._postsApi.getNewsPosts({ filter: category.filter, page: pageNum });
				// return this._cms.getPosts(`posts-${category.key}-${pageNum}`, pageNum, postOptions);
			}),
			tap(posts => {
				if (posts.items) this.isLoading = false;
			})
		);

		setTimeout(() => {
			this.paginationForm.get('page').setValue(1);
			this.categoryForm.get('category').setValue('');
		}, 0);
	}

	public onPageChange(page) {
		// this._pageChange$ = of(page);
		this.paginationForm.get('page').setValue(page as Number);
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CMSService, Post, PostState } from '@app/cms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Broadcast } from '@app/cms/models/broadcast.model';

@Component({
	selector: 'app-broadcasts-month',
	templateUrl: './broadcasts-month.component.html',
	styleUrls: ['./broadcasts-month.component.scss']
})
export class BroadcastsMonthComponent implements OnInit {
	@Input()
	public broadcasts: Partial<Broadcast>[];
	@Input()
	public monthTitle: string;

	constructor(private _cms: CMSService, private _route: ActivatedRoute, private _router: Router) {}

	ngOnInit() {}
}

import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCMS from '../store';
import { Injectable } from '@angular/core';
import { take, tap, filter, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoaderState, Dictionary } from '@app/cms/models/post.model';
import { Filter, DEFAULT_POST_COLLECTON, Pagination, PageNum, PAGE_NEXT, PAGE_PREV } from '@app/cms/models/post.model';
// import { CMSModule } from '../cms.module';

export type GetPostOptions = {
	setMeta?: boolean;
	onError?: () => any;
};

export type GetPostsOptions = {
	filter?: Filter;
	page?: number;
	count?: number;
	loadPostsImmediately?: boolean;
	onError?: () => any;
};

export const RELOAD_COLLECTION = true;

// @Injectable({
// 	providedIn: CMSModule
// })
@Injectable()
export class CMSService {
	constructor(private _store: Store<fromCMS.State>, private _router: Router) {}
	// POSTS

	private async _loadPosts(collection: string, page: PageNum = PAGE_NEXT, count?: number) {
		// console.log('CMS _loadPosts', collection, page);
		if (page === PAGE_NEXT || page === PAGE_PREV) {
			let current_page = 0;
			this._store
				.select(fromCMS.getPostsPagination(collection))
				.pipe(first())
				.subscribe(pagination => {
					if (typeof pagination !== 'undefined') {
						current_page = pagination.current_page;
					}
				});
			if (page === PAGE_NEXT) page = current_page + 1;
			if (page === PAGE_PREV) page = current_page - 1;
		}
		this._store.dispatch(new fromCMS.PostsLoadAction({ collection, page, count }));
	}

	public loadPosts(collection: string = DEFAULT_POST_COLLECTON, page: PageNum = PAGE_NEXT): void {
		// console.log('CMS loadPostCollection', collection, page);
		this._loadPosts(collection, page);
	}

	public setPostsFilter(
		collection: string = DEFAULT_POST_COLLECTON,
		filter: Filter = {},
		updateCollection: boolean = false
	): void {
		// console.log('CMS setPostCollectionFilter', collection, filter, updateCollection);
		this._store.dispatch(new fromCMS.PostsSetFilterAction({ collection, filter }));
		if (updateCollection) this.loadPosts(collection, 1);
	}

	public getPosts(
		collection: string = DEFAULT_POST_COLLECTON,
		page: PageNum = PAGE_NEXT,
		options?: GetPostsOptions
	): Observable<fromCMS.PostState[]> {
		// console.log('CMS getPosts', collection, options);
		options = { loadPostsImmediately: false, ...options };

		if (options.filter) this.setPostsFilter(collection, options.filter);
		if (options.loadPostsImmediately) this.loadPosts(collection, page);

		return this._store.select(fromCMS.getPosts(collection));
	}

	public getPostsLoaderState(collection: string = DEFAULT_POST_COLLECTON): Observable<LoaderState> {
		return this._store.select(fromCMS.getPostsLoaderState(collection));
	}

	public getPostsPagination(collection: string = DEFAULT_POST_COLLECTON): Observable<Pagination> {
		return this._store.select(fromCMS.getPostsPagination(collection));
	}

	// POST

	public getPost(slug: string, options: Dictionary<string> = {}): Observable<fromCMS.PostState> {
		this._loadPost(slug, options);
		return this._store
			.select(fromCMS.getPost(slug))
			.pipe
			// filter(post => typeof post !== 'undefined'),
			// tap(post => {
			// 	if (post._loaderState.failed) {
			// 		console.error('post load failed');
			// 		if (typeof options.onError === 'function') options.onError();
			// 		// this._router.navigate(['hiba']);
			// 		return;
			// 	}
			// 	if (post._loaderState.loaded) {
			// 		if (options && options.setMeta) {
			// 			this._meta.setMeta(post);
			// 		}
			// 	}
			// }),
			// tag(`post:${slug}`)
			();
	}

	private async _loadPost(slug: string, options?: Dictionary<string>) {
		if (
			!(await this._store
				.select(fromCMS.isPostLoadedorLoading(slug))
				.pipe(first())
				.toPromise())
		) {
			this._store.dispatch(new fromCMS.PostLoadAction({ slug, options }));
		}
	}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./header/header.component.ngfactory";
import * as i3 from "./header/header.component";
import * as i4 from "@angular/router";
import * as i5 from "../cms/services/settings.service";
import * as i6 from "./footer/footer.component.ngfactory";
import * as i7 from "./footer/footer.component";
import * as i8 from "./shell.component";
var styles_ShellComponent = [i0.styles];
var RenderType_ShellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShellComponent, data: {} });
export { RenderType_ShellComponent as RenderType_ShellComponent };
export function View_ShellComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], [[2, "collapsed", null]], [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).checkScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.HeaderComponent, [i4.Router, i5.SettingsAPIService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i6.View_FooterComponent_0, i6.RenderType_FooterComponent)), i1.ɵdid(8, 114688, null, 0, i7.FooterComponent, [i5.SettingsAPIService], null, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).headerCollapsed; _ck(_v, 0, 0, currVal_0); }); }
export function View_ShellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shell", [], null, null, null, View_ShellComponent_0, RenderType_ShellComponent)), i1.ɵdid(1, 114688, null, 0, i8.ShellComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShellComponentNgFactory = i1.ɵccf("app-shell", i8.ShellComponent, View_ShellComponent_Host_0, {}, {}, []);
export { ShellComponentNgFactory as ShellComponentNgFactory };

<div class="container-fluid">
  <div class="jumbotron text-center">
    <h1>
      <span translate>APP_NAME</span>
    </h1>
    <p>
      <i class="far fa-bookmark"></i> <span translate>Version</span> {{version}}
    </p>
  </div>
</div>

import { Component, OnInit, HostListener, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsAPIService } from '@app/cms/services/settings.service';
import { Observable } from 'rxjs';
import { Settings } from '@app/cms';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	@HostBinding('class.collapsed')
	public headerCollapsed: boolean = false;

	public isCollapsed: boolean = true;
	public isSearch: boolean = false;

	public settings$: Observable<Settings>;

	constructor(private router: Router, private _settingsApi: SettingsAPIService) {}

	ngOnInit() {
		this.settings$ = this._getSettings();
	}

	public openSearch() {
		this.isSearch = !this.isSearch;
		const body = document.body as HTMLElement;
		body.style.overflow = 'hidden';
	}

	public closeSearch() {
		this.isSearch = !this.isSearch;
		const body = document.body as HTMLElement;
		body.style.overflow = 'auto';
	}

	public searchString(query: string) {
		this.router.navigate(['/talalatok/' + query]).then(() => {
			this.closeSearch();
		});
	}

	@HostListener('window:scroll', [])
	checkScroll() {
		this.headerCollapsed = window.pageYOffset >= 10;
	}

	private _getSettings(): Observable<Settings> {
		return this._settingsApi.getSettings();
	}
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Settings } from '@app/cms';
import { SettingsAPIService } from '@app/cms/services/settings.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	public settings$: Observable<Settings>;
	public date: Date;

	constructor(private _settingsApi: SettingsAPIService) {}

	ngOnInit() {
		this.date = new Date();
		this.settings$ = this._getSettings();
	}

	private _getSettings(): Observable<Settings> {
		return this._settingsApi.getSettings();
	}
}

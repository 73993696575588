import { Component, OnInit, Input } from '@angular/core';
import { PostState } from '@app/cms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlbumComponent } from '../../../modals/album/album.component';

@Component({
	selector: 'app-image-block',
	templateUrl: './image-block.component.html',
	styleUrls: ['./image-block.component.scss']
})
export class ImageBlockComponent implements OnInit {
	@Input()
	public content: any;

	constructor(private _modal: NgbModal) {}

	ngOnInit() {}

	public openAlbum(image): Promise<any> {
		const modal = this._modal.open(AlbumComponent, { windowClass: 'album-modal', centered: true });
		modal.componentInstance.images = [image];
		return modal.result;
	}
}

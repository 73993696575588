import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VgAPI } from 'videogular2/compiled/core';
import { PostState, Settings } from '@app/cms';

@Component({
	selector: 'app-live-audio',
	templateUrl: './live-audio.component.html',
	styleUrls: ['./live-audio.component.scss']
})
export class LiveAudioComponent implements OnInit {
	public api: VgAPI;

	@Output()
	public ended = new EventEmitter<boolean>();

	@Input()
	public settings: Settings;

	public isPlaying: boolean = false;

	public playing = new EventEmitter<boolean>(false);

	public sources: Array<Object>;

	public title: string;

	constructor() {}

	ngOnInit() {
		if (this.settings.radio_on) {
			const radioSrc = this.settings.radio_link || 'http://r6.hu:8000/rallyeradio';
			const radioTitle = this.settings.radio_title || 'Rallye Radio';
			this.sources = [
				{
					src: radioSrc,
					type: 'audio/mpeg'
				}
			];

			this.title = radioTitle;

			this.playing.subscribe(value => (this.isPlaying = value));
		}
	}

	public onPlayerReady(api: VgAPI) {
		this.api = api;

		this.api.getDefaultMedia().subscriptions.ended.subscribe(() => {
			this.ended.emit(true);
		});

		this.api.getDefaultMedia().subscriptions.playing.subscribe(() => {
			this.playing.emit(true);
		});

		this.api.getDefaultMedia().subscriptions.pause.subscribe(() => {
			this.playing.emit(false);
		});
	}

	public playRadio() {
		this.api.play();
	}

	public stopRadio() {
		this.api.pause();
	}
}

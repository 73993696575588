import { Component, OnInit, Input } from '@angular/core';
import { PostState } from '@app/cms';

@Component({
	selector: 'app-small-post-sidebar',
	templateUrl: './small-post-sidebar.component.html',
	styleUrls: ['./small-post-sidebar.component.scss']
})
export class SmallPostSidebarComponent implements OnInit {
	@Input()
	public post: PostState;

	constructor() {}

	ngOnInit() {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { QuoteService } from './quote.service';
import { CMSModule } from '@app/cms/cms.module';

import { reducers, effects } from '@app/cms/store';

@NgModule({
	imports: [
		StoreModule.forFeature('cms', reducers),
		EffectsModule.forFeature(effects),

		CommonModule,
		TranslateModule,
		CoreModule,
		SharedModule,
		Angulartics2Module,
		HomeRoutingModule,
		CMSModule
	],
	declarations: [HomeComponent],
	providers: [QuoteService]
})
export class HomeModule {}

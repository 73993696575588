import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, pluck, catchError } from 'rxjs/operators';

import { Post, Filter, Pagination } from '../models/post.model';
import { Dictionary } from '@ngrx/entity';
import { Page } from '@app/cms';
import { Router } from '@angular/router';

@Injectable()
export class PostsAPIService {
	private readonly endpoint = '/api/posts';
	private readonly newsEndpoint = '/api/news';
	private readonly pageEndpoint = '/api/pages';

	constructor(private http: HttpClient, private _router: Router) {}

	public getPosts(
		options: { filter?: Filter; page?: number; count?: number } = {}
	): Observable<{ items: Partial<Post>[]; pagination: Pagination }> {
		// console.log('PostsAPIService.getPosts', options);
		const endpoint = this.endpoint;
		let params = new HttpParams();
		if (options.filter) {
			// categories
			if (Array.isArray(options.filter.category) && options.filter.category.length > 0) {
				params = params.set('category', options.filter.category.join(','));
			}
			// query
			if (typeof options.filter.query === 'string') {
				params = params.set('query', options.filter.query);
			}
		}
		if (typeof options.page === 'number') {
			params = params.set('page', options.page.toString());
		}
		if (typeof options.count === 'number') {
			params = params.set('count', options.count.toString());
		}
		// console.log(`XHR to ${endpoint} sent.`, params.toString());
		return this.http.get<{ data: { items: Partial<Post>[]; pagination: Pagination } }>(endpoint, { params }).pipe(
			// tap(response => console.log(`XHR to ${endpoint} finished.`, response)),
			pluck('data')
			// map(response => ({ items: response.data.items, pagination: {} })),
		);
	}

	public getNewsPosts(
		options: { filter?: Filter; page?: number; count?: number } = {}
	): Observable<{ items: Partial<Post>[]; pagination: Pagination }> {
		// console.log('PostsAPIService.getPosts', options);
		const endpoint = this.newsEndpoint;
		let params = new HttpParams();
		if (options.filter) {
			// categories
			if (Array.isArray(options.filter.category) && options.filter.category.length > 0) {
				params = params.set('category', options.filter.category.join(','));
			}
			// query
			if (typeof options.filter.query === 'string') {
				params = params.set('query', options.filter.query);
			}
		}
		if (typeof options.page === 'number') {
			params = params.set('page', options.page.toString());
		}
		if (typeof options.count === 'number') {
			params = params.set('count', options.count.toString());
		}
		// console.log(`XHR to ${endpoint} sent.`, params.toString());
		return this.http.get<{ data: { items: Partial<Post>[]; pagination: Pagination } }>(endpoint, { params }).pipe(
			// tap(response => console.log(`XHR to ${endpoint} finished.`, response)),
			pluck('data')
			// map(response => ({ items: response.data.items, pagination: {} })),
		);
	}

	public getPost(slug: string): Observable<Partial<Post>>;
	public getPost(slug: string, options?: Dictionary<string>): Observable<Partial<Post>>;
	public getPost(slug: string, filter: Filter, options?: Dictionary<string>): Observable<Partial<Post>>;
	public getPost(slug: string, arg?: any, options?: Dictionary<string>): Observable<Partial<Post>> {
		let filter: Filter = {};
		if (typeof arg === 'undefined') {
			filter = {};
		} else if (typeof arg === 'string') {
			filter = { ...filter };
		} else if (arg.isArray) {
			filter = { ...filter };
		} else if (typeof arg === 'object') {
			filter = { ...filter, ...arg };
		}
		const endpoint = this.endpoint + '/' + slug;
		let params = new HttpParams();
		if (options && options.preview) {
			params = params.set('preview', options.preview);
		}
		// console.log(`XHR to ${endpoint} sent.`);
		return this.http.get<{ data: Partial<Post> }>(endpoint, { params }).pipe(
			// tap(response => console.log(`XHR to ${endpoint} finished.`, response)),
			pluck('data')
		);
	}

	public getPage(slug: string): Observable<Page> {
		const endpoint = this.pageEndpoint + '/' + slug;
		// console.log(`XHR to ${endpoint} sent.`);
		return this.http.get<{ data: Page }>(endpoint).pipe(
			// tap(response => console.log(`XHR to ${endpoint} finished.`, response)),
			catchError(() => this._router.navigate(['/404'], { skipLocationChange: true })),
			pluck('data')
		);
	}
}

<ng-container *ngxLet="ad$ | async as ad">
	<ng-container *ngIf="ad && ad[location]">
		<div *ngIf="ad[location].type === 'image'" class="w-100 d-none d-md-block">
			<a href="{{ ad[location].link }}" target="_blank">
				<img class="img-fluid" src="{{ ad[location].image }}">
			</a>
		</div>
		<div *ngIf="ad[location].type === 'image'" class="w-100 d-block d-md-none">
			<a href="{{ ad[location].link }}" target="_blank">
				<img class="img-fluid" src="{{ ad[location].mobile_image ? ad[location].mobile_image : ad[location].image}}">
			</a>
		</div>
		<div *ngIf="ad[location].type === 'link'" class="w-100 d-none d-md-block">
			<a href="{{ ad[location].link }}" target="_blank">
				<img class="img-fluid" src="{{ ad[location].url }}">
			</a>
		</div>
		<div *ngIf="ad[location].type === 'link'" class="w-100 d-block d-md-none">
			<a href="{{ ad[location].link }}" target="_blank">
				<img class="img-fluid" src="{{ ad[location].mobile_url ? ad[location].mobile_url : ad[location].url }}">
			</a>
		</div>
		<div *ngIf="ad[location].type === 'html'" [innerHTML]="ad[location].html" class="w-100 html-ard d-none d-md-block"></div>
		<div *ngIf="ad[location].type === 'html'" [innerHTML]="ad[location].mobile_html ? ad[location].mobile_html : ad[location].html" class="w-100 html-ard" d-block d-md-none></div>
	</ng-container>
</ng-container>

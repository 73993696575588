<div class="fixed-header">
	<nav class="navbar navbar-expand navbar-light bg-blue">
		<div class="container">
			<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
				<ul class="navbar-nav mr-auto d-none d-md-block">
					<li class="nav-item search" (click)="openSearch()">
						<img src="/assets/img/search.svg" alt="" /> <span class="d-inline">Keresés</span>
					</li>
				</ul>
				<ul class="navbar-nav mx-md-auto text-center">
					<li class="nav-item">
						<a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
							<img src="/assets/img/logo.svg" class="img-fluid logo" alt="" />
						</a>
					</li>
				</ul>
				<ng-container *ngxLet="settings$ | async as settings">
					<ul class="navbar-nav ml-auto" [class.default-width]="!settings || (settings && !settings.radio_on)">
						<li class="nav-item">
							<app-live-audio *ngIf="settings && settings.radio_on" [settings]="settings"></app-live-audio>
						</li>
					</ul>
				</ng-container>
			</div>
		</div>
	</nav>
	<nav class="navbar navbar-expand-lg navbar-light bg-white mb-4">
		<div class="container">
			<li class="nav-item search ml-2 d-block d-md-none" (click)="openSearch()">
				<img src="/assets/img/search_black.svg" alt="" />
			</li>
			<button
				class="navbar-toggler ml-auto"
				type="button"
				(click)="isCollapsed = !isCollapsed"
				data-toggle="collapse"
				data-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation"
			>
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
				<ul class="navbar-nav mx-auto">
					<a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
						<li class="nav-item">Címlap</li>
					</a>
					<a routerLink="/hirek" routerLinkActive="active"> <li class="nav-item">Hírek</li> </a>
					<a routerLink="/adasok" routerLinkActive="active"> <li class="nav-item">Adások</li> </a>
					<a routerLink="/program" routerLinkActive="active"> <li class="nav-item">Program</li> </a>
				</ul>
			</div>
		</div>
	</nav>
</div>
<div class="search-container" *ngIf="isSearch">
	<span class="close" (click)="closeSearch()">&times;</span>
	<div class="search-input">
		<input type="text" #searchField class="form-control search" (keyup.enter)="searchString(searchField.value)" />
		<label>
			<span (click)="searchString(searchField.value)"><img src="assets/img/search.svg" alt=""/></span
		></label>
	</div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./text-block.component";
var styles_TextBlockComponent = [i0.styles];
var RenderType_TextBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextBlockComponent, data: {} });
export { RenderType_TextBlockComponent as RenderType_TextBlockComponent };
export function View_TextBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.content.key, ""); var currVal_1 = _co.content.attributes.text; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_TextBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-text-block", [], null, null, null, View_TextBlockComponent_0, RenderType_TextBlockComponent)), i1.ɵdid(1, 114688, null, 0, i2.TextBlockComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextBlockComponentNgFactory = i1.ɵccf("app-text-block", i2.TextBlockComponent, View_TextBlockComponent_Host_0, { content: "content" }, {}, []);
export { TextBlockComponentNgFactory as TextBlockComponentNgFactory };

import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { ResultComponent } from '@app/result/result.component';
import { NewsComponent } from '@app/news/news.component';
import { PostComponent } from '@app/news/post/post.component';
import { BroadcastComponent } from '@app/broadcast/broadcast.component';
import { LiveComponent } from '@app/broadcast/live/live.component';
import { RecordedComponent } from '@app/broadcast/recorded/recorded.component';
import { ProgramComponent } from '@app/program/program.component';
import { NotFoundComponent } from '@app/shared';
import { PageComponent } from '@app/page/page.component';
const ɵ0 = { category: '', title: extract('Hírek - RallyeRadio') }, ɵ1 = { title: extract('Hírek - RallyeRadio') }, ɵ2 = { category: '', title: extract('Adások - RallyeRadio') }, ɵ3 = { title: extract('Élő adás') }, ɵ4 = { category: '', title: extract('Program - RallyeRadio') }, ɵ5 = { title: extract('Program - RallyeRadio') }, ɵ6 = { title: extract('Hiba - RallyeRadio') }, ɵ7 = { title: extract('Hiba - RallyeRadio') };
const routes = [
    Shell.childRoutes([{ path: '', component: HomeComponent, pathMatch: 'full' }]),
    Shell.childRoutes([{ path: 'talalatok/:query', component: ResultComponent }]),
    Shell.childRoutes([
        { path: 'hirek', component: NewsComponent, data: ɵ0 }
    ]),
    Shell.childRoutes([
        { path: 'hirek/:category', component: NewsComponent, data: ɵ1 }
    ]),
    Shell.childRoutes([{ path: 'hirek/:category/:slug', component: PostComponent }]),
    Shell.childRoutes([
        {
            path: 'adasok',
            component: BroadcastComponent,
            data: ɵ2
        }
    ]),
    Shell.childRoutes([{ path: 'adasok/:category', component: BroadcastComponent }]),
    Shell.childRoutes([{ path: 'adasok/elo/:slug', component: LiveComponent, data: ɵ3 }]),
    Shell.childRoutes([{ path: 'adasok/:category/:slug', component: RecordedComponent }]),
    Shell.childRoutes([
        {
            path: 'program',
            component: ProgramComponent,
            data: ɵ4
        }
    ]),
    Shell.childRoutes([
        { path: 'program/:category', component: ProgramComponent, data: ɵ5 }
    ]),
    Shell.childRoutes([
        {
            path: 'oldalak/:slug',
            component: PageComponent
        }
    ]),
    Shell.childRoutes([
        {
            path: 'error/404',
            component: NotFoundComponent,
            data: ɵ6
        },
        {
            path: '**',
            component: NotFoundComponent,
            data: ɵ7
        }
    ])
];
export class HomeRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };

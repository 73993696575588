export type PostID = number | string;

export type Post = {
	id?: PostID;

	slug: string;

	title: string;
	content?: any[];
	lead?: string;

	featured_image?: string;
	featured_image_thumbnail?: string;

	cover_image?: string;
	cover_credits?: string;
	type?: string;

	publish_date?: string;
	updated_at?: string;
	published_ago?: string;

	author?: Author;
	category?: Category;

	post_suggestions?: Post[];
	top_posts?: Post[];
} & {
	[key: string]: any;
};

export interface LoaderState {
	loading: boolean;
	loaded: boolean;
	failed: boolean;
}

export interface ResourceLoaderState extends LoaderState {
	partiallyLoaded: boolean;
}

export type ImageBlock = Dictionary<Image>;

export interface Category {
	slug: string;
	name: string;
}

export interface Author {
	name: string;
}

export interface Image {
	width: number;
	height: number;
	url: string;
}

export interface Pagination {
	total: number;
	current_page: number;
	last_page: number;
	per_page: number;
	from: number;
	to: number;
}
export type PageNum = number | 'next' | 'prev';
export const PAGE_NEXT = 'next';
export const PAGE_PREV = 'prev';

export interface Collection {
	pages: DictionaryNum<PostID[]>;
	loadedEntities: PostID[];
	filter?: Filter;
	_loaderState?: LoaderState;
	_pagesLoaded?: number;
	pagination?: Pagination;
}
export type Collections = Dictionary<Collection>;
export const DEFAULT_POST_COLLECTON = '_default';

export type Dictionary<T> = {
	[key: string]: T;
};

export type DictionaryNum<T> = {
	[key: number]: T;
};

export interface Filter {
	category?: string[];
	query?: string;
}

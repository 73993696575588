import { Component, Input, OnInit } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CMSService } from '@app/cms';
import { Observable } from 'rxjs';

export type AlbumParams = {
	slug: string;
};

@Component({
	selector: 'app-album',
	templateUrl: './album.component.html',
	styleUrls: ['./album.component.scss']
})
export class AlbumComponent implements OnInit {
	@Input()
	public images;

	@Input()
	public type;

	// public gallery$: Observable<Gallery>;

	config: SwiperConfigInterface = {
		slidesPerView: 1,
		centeredSlides: true,
		spaceBetween: 0,
		navigation: true,
		loop: true,
		observer: true
	};

	constructor(public activeModal: NgbActiveModal, private _location: PlatformLocation, private _cms: CMSService) {}

	public ngOnInit() {
		this._location.onPopState(() => this.activeModal.dismiss());
		// this._locationSubscription = this.location.subscribe(x => {
		// 	console.log(x);
		// 	this.activeModal.close();
		// });

		// this.gallery$ = this._cms.getPost(this.slug, 'gallery');
	}
}

import { Routes } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { AboutComponent } from './about.component';
const ɵ0 = { title: extract('About') };
const routes = [
    Shell.childRoutes([{ path: 'about', component: AboutComponent, data: ɵ0 }])
];
export class AboutRoutingModule {
}
export { ɵ0 };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./small-post-video.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./small-post-video.component";
var styles_SmallPostVideoComponent = [i0.styles];
var RenderType_SmallPostVideoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmallPostVideoComponent, data: {} });
export { RenderType_SmallPostVideoComponent as RenderType_SmallPostVideoComponent };
export function View_SmallPostVideoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "holder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["class", "img-fluid mb-2"]], [[8, "src", 4], [8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "tag"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "bg-orange": 0, "bg-purple": 1 }), (_l()(), i1.ɵted(12, null, ["#", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "title eq"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(19, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(2, "/adasok/", _co.broadcast.category.slug, "/", (_co.broadcast.slug || ""), ""); _ck(_v, 3, 0, currVal_2); var currVal_5 = "tag"; var currVal_6 = _ck(_v, 11, 0, (_co.broadcast.category.slug === "rallye"), (_co.broadcast.category.slug === "street")); _ck(_v, 10, 0, currVal_5, currVal_6); var currVal_10 = i1.ɵinlineInterpolate(2, "/adasok/", _co.broadcast.category.slug, "/", (_co.broadcast.slug || ""), ""); _ck(_v, 17, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.broadcast.featured_image || "/assets/img/street-default.png"); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.broadcast.title, ""); _ck(_v, 5, 0, currVal_3, currVal_4); var currVal_7 = (_co.broadcast.category.slug || "rallye"); _ck(_v, 12, 0, currVal_7); var currVal_8 = i1.ɵnov(_v, 17).target; var currVal_9 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_8, currVal_9); var currVal_11 = (_co.broadcast.title || ""); _ck(_v, 20, 0, currVal_11); }); }
export function View_SmallPostVideoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-small-post-video", [], null, null, null, View_SmallPostVideoComponent_0, RenderType_SmallPostVideoComponent)), i1.ɵdid(1, 114688, null, 0, i4.SmallPostVideoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmallPostVideoComponentNgFactory = i1.ɵccf("app-small-post-video", i4.SmallPostVideoComponent, View_SmallPostVideoComponent_Host_0, { broadcast: "broadcast" }, {}, []);
export { SmallPostVideoComponentNgFactory as SmallPostVideoComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./broadcasts-month.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/posts/small-post-video/small-post-video.component.ngfactory";
import * as i3 from "../../shared/posts/small-post-video/small-post-video.component";
import * as i4 from "@angular/common";
import * as i5 from "./broadcasts-month.component";
import * as i6 from "../../cms/services/cms.service";
import * as i7 from "@angular/router";
var styles_BroadcastsMonthComponent = [i0.styles];
var RenderType_BroadcastsMonthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BroadcastsMonthComponent, data: {} });
export { RenderType_BroadcastsMonthComponent as RenderType_BroadcastsMonthComponent };
function View_BroadcastsMonthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-small-post-video", [], null, null, null, i2.View_SmallPostVideoComponent_0, i2.RenderType_SmallPostVideoComponent)), i1.ɵdid(3, 114688, null, 0, i3.SmallPostVideoComponent, [], { broadcast: [0, "broadcast"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BroadcastsMonthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BroadcastsMonthComponent_1)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.broadcasts; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.monthTitle; _ck(_v, 5, 0, currVal_0); }); }
export function View_BroadcastsMonthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-broadcasts-month", [], null, null, null, View_BroadcastsMonthComponent_0, RenderType_BroadcastsMonthComponent)), i1.ɵdid(1, 114688, null, 0, i5.BroadcastsMonthComponent, [i6.CMSService, i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BroadcastsMonthComponentNgFactory = i1.ɵccf("app-broadcasts-month", i5.BroadcastsMonthComponent, View_BroadcastsMonthComponent_Host_0, { broadcasts: "broadcasts", monthTitle: "monthTitle" }, {}, []);
export { BroadcastsMonthComponentNgFactory as BroadcastsMonthComponentNgFactory };

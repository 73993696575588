import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CMSService, Post, PostState } from '@app/cms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
	selector: 'app-news-month',
	templateUrl: './news-month.component.html',
	styleUrls: ['./news-month.component.scss']
})
export class NewsMonthComponent implements OnInit {
	@Input()
	public posts: Partial<Post>[];
	@Input()
	public monthTitle: string;

	public selectedPost: Partial<Post>;
	public sidePosts: Partial<Post>[];
	public imagePosts: Partial<Post>[];

	constructor(private _cms: CMSService, private _route: ActivatedRoute, private _router: Router) {}

	ngOnInit() {
		this.selectedPost = this.posts.shift();
		this.sidePosts = this.posts.slice(0, 2);
		this.posts = this.posts.slice(2);
		this.imagePosts = this.posts.slice(0, 4);
		this.posts = this.posts.slice(4);
	}

	public getMonthName(month: string) {
		const monthSplitted = month.split('#');

		if (monthSplitted[1]) {
			return monthSplitted[1];
		}

		return month;
	}
}

import { Component, OnInit, Input } from '@angular/core';
import { Broadcast } from '@app/cms/models/broadcast.model';

@Component({
	selector: 'app-small-post-video',
	templateUrl: './small-post-video.component.html',
	styleUrls: ['./small-post-video.component.scss']
})
export class SmallPostVideoComponent implements OnInit {
	@Input()
	public broadcast: Broadcast;

	constructor() {}

	ngOnInit() {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./post-gallery-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./post-gallery-block.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
var styles_PostGalleryBlockComponent = [i0.styles];
var RenderType_PostGalleryBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PostGalleryBlockComponent, data: {} });
export { RenderType_PostGalleryBlockComponent as RenderType_PostGalleryBlockComponent };
function View_PostGalleryBlockComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "figcaption", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.attributes.title.title; _ck(_v, 1, 0, currVal_0); }); }
function View_PostGalleryBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "figure", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openAlbum(_co.content.attributes.images) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostGalleryBlockComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.content.attributes.title && _co.content.attributes.title.title); _ck(_v, 9, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.attributes.images[0].medium; _ck(_v, 6, 0, currVal_0); }); }
export function View_PostGalleryBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PostGalleryBlockComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.content.attributes.images.length; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.content.key, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_PostGalleryBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-post-gallery-block", [], null, null, null, View_PostGalleryBlockComponent_0, RenderType_PostGalleryBlockComponent)), i1.ɵdid(1, 114688, null, 0, i3.PostGalleryBlockComponent, [i4.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PostGalleryBlockComponentNgFactory = i1.ɵccf("app-post-gallery-block", i3.PostGalleryBlockComponent, View_PostGalleryBlockComponent_Host_0, { content: "content" }, {}, []);
export { PostGalleryBlockComponentNgFactory as PostGalleryBlockComponentNgFactory };

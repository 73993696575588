import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbButtonsModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2Module } from 'angulartics2';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AboutModule } from './about/about.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ResultComponent } from './result/result.component';
import { PageComponent } from './page/page.component';
import { NewsComponent } from './news/news.component';
import { PostComponent } from './news/post/post.component';
import { PostFullHeaderComponent } from './news/post-full-header/post-full-header.component';
import { BroadcastComponent } from './broadcast/broadcast.component';
import { LiveComponent } from './broadcast/live/live.component';
import { RecordedComponent } from './broadcast/recorded/recorded.component';
import { ProgramComponent } from './program/program.component';
import { ProgramCardComponent } from './program/program-card/program-card.component';
import { NewsMonthComponent } from './news/news-month/news-month.component';
import { BroadcastsMonthComponent } from './broadcast/broadcasts-month/broadcasts-month.component';
import { ProgramsMonthComponent } from './program/programs-month/programs-month.component';

import { NGRX_CONFIG } from './config/ngrx.config';
import { FacebookModule } from 'ngx-facebook';
import { ClipboardModule } from 'ngx-clipboard';

import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

const bugsnagClient = bugsnag('c22a1ac152998e5cd3d49ba3d8848a78');

export function errorHandlerFactory() {
	return new BugsnagErrorHandler(bugsnagClient);
}

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		TranslateModule.forRoot(),
		NgbModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		CoreModule,
		SharedModule,
		ShellModule,
		HomeModule,
		AboutModule,
		ReactiveFormsModule,
		Angulartics2Module.forRoot([]),
		FacebookModule.forRoot(),
		ClipboardModule,
		...NGRX_CONFIG,
		AppRoutingModule // must be imported as the last module as it contains the fallback route
	],
	declarations: [
		AppComponent,
		ResultComponent,
		NewsComponent,
		NewsMonthComponent,
		PageComponent,
		PostComponent,
		PostFullHeaderComponent,
		BroadcastComponent,
		BroadcastsMonthComponent,
		LiveComponent,
		RecordedComponent,
		ProgramComponent,
		ProgramsMonthComponent,
		ProgramCardComponent
	],
	providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }],
	bootstrap: [AppComponent]
})
export class AppModule {}

<div class="row">
	<div class="col-md-12">
		<h3>{{ monthTitle }}</h3>
	</div>
</div>
<div class="row">
	<div class="col-md-3" *ngFor="let broadcast of broadcasts">
		<app-small-post-video [broadcast]="broadcast"></app-small-post-video>
	</div>
</div>

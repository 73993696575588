import { Action } from '@ngrx/store';

import { Post, Filter, Pagination } from '../../models/post.model';
import { Dictionary } from '@app/cms/models/post.model';

export const POSTS_LOAD = '[Posts] load';
export const POSTS_LOAD_SUCCESS = '[Posts] load success';
export const POSTS_LOAD_FAILURE = '[Posts] load failure';

export const POSTS_SET_FILTER = '[Posts] set filter';

export const POST_LOAD = '[Post details] load';
export const POST_LOAD_SUCCESS = '[Post details] load success';
export const POST_LOAD_FAILURE = '[Post details] load failure';

export class PostsLoadAction implements Action {
	public readonly type = POSTS_LOAD;
	constructor(public payload: { collection: string; page: number; count?: number }) {}
}

export class PostsLoadSuccessAction implements Action {
	public readonly type = POSTS_LOAD_SUCCESS;
	constructor(public payload: { collection: string; items: Partial<Post>[]; pagination: Pagination }) {}
}

export class PostsLoadFailureAction implements Action {
	public readonly type = POSTS_LOAD_FAILURE;
	constructor(public payload: { collection: string }) {}
}

export class PostsSetFilterAction implements Action {
	public readonly type = POSTS_SET_FILTER;
	constructor(public payload: { collection: string; filter: Filter }) {}
}

export class PostLoadAction implements Action {
	public readonly type = POST_LOAD;
	constructor(public payload: { slug: string; options: Dictionary<string> }) {}
}

export class PostLoadSuccessAction implements Action {
	public readonly type = POST_LOAD_SUCCESS;
	constructor(public payload: { post: Partial<Post> }) {}
}

export class PostLoadFailureAction implements Action {
	public readonly type = POST_LOAD_FAILURE;
	constructor(public payload: { slug: string }) {}
}

export type PostActions =
	| PostsLoadAction
	| PostsLoadSuccessAction
	| PostsLoadFailureAction
	| PostsSetFilterAction
	| PostLoadAction
	| PostLoadSuccessAction
	| PostLoadFailureAction;

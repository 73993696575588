import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { ResultComponent } from '@app/result/result.component';
import { NewsComponent } from '@app/news/news.component';
import { PostComponent } from '@app/news/post/post.component';
import { PostFullHeaderComponent } from '@app/news/post-full-header/post-full-header.component';
import { BroadcastComponent } from '@app/broadcast/broadcast.component';
import { LiveComponent } from '@app/broadcast/live/live.component';
import { RecordedComponent } from '@app/broadcast/recorded/recorded.component';
import { ProgramComponent } from '@app/program/program.component';
import { NotFoundComponent } from '@app/shared';
import { PageComponent } from '@app/page/page.component';

const routes: Routes = [
	Shell.childRoutes([{ path: '', component: HomeComponent, pathMatch: 'full' }]),
	Shell.childRoutes([{ path: 'talalatok/:query', component: ResultComponent }]),
	Shell.childRoutes([
		{ path: 'hirek', component: NewsComponent, data: { category: '', title: extract('Hírek - RallyeRadio') } }
	]),
	Shell.childRoutes([
		{ path: 'hirek/:category', component: NewsComponent, data: { title: extract('Hírek - RallyeRadio') } }
	]),
	Shell.childRoutes([{ path: 'hirek/:category/:slug', component: PostComponent }]),
	Shell.childRoutes([
		{
			path: 'adasok',
			component: BroadcastComponent,
			data: { category: '', title: extract('Adások - RallyeRadio') }
		}
	]),
	Shell.childRoutes([{ path: 'adasok/:category', component: BroadcastComponent }]),
	Shell.childRoutes([{ path: 'adasok/elo/:slug', component: LiveComponent, data: { title: extract('Élő adás') } }]),
	Shell.childRoutes([{ path: 'adasok/:category/:slug', component: RecordedComponent }]),
	Shell.childRoutes([
		{
			path: 'program',
			component: ProgramComponent,
			data: { category: '', title: extract('Program - RallyeRadio') }
		}
	]),
	Shell.childRoutes([
		{ path: 'program/:category', component: ProgramComponent, data: { title: extract('Program - RallyeRadio') } }
	]),
	Shell.childRoutes([
		{
			path: 'oldalak/:slug',
			component: PageComponent
		}
	]),
	Shell.childRoutes([
		{
			path: 'error/404',
			component: NotFoundComponent,
			data: { title: extract('Hiba - RallyeRadio') }
		},
		{
			path: '**',
			component: NotFoundComponent,
			data: { title: extract('Hiba - RallyeRadio') }
		}
	])
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled',
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule],
	providers: []
})
export class HomeRoutingModule {}

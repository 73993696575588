<div id="{{ content.key }}" class="row">
	<ng-container *ngIf="content.attributes.images.length">
		<div class="col-12 text-center">
			<figure>
				<img [src]="content.attributes.images[0].medium" (click)="openAlbum(content.attributes.images)" />
				<figcaption *ngIf="content.attributes.title && content.attributes.title.title">{{ content.attributes.title.title }}</figcaption>
			</figure>
		</div>
	</ng-container>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { Broadcast } from '@app/cms/models/broadcast.model';

@Component({
	selector: 'app-selected-post-video',
	templateUrl: './video.component.html',
	styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
	@Input()
	public broadcast: Broadcast;

	constructor() {}

	ngOnInit() {}
}

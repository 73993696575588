import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Broadcast, BroadcastState } from '@app/cms/models/broadcast.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BroadcastAPIService } from '@app/cms/services/broadcast.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { FBVideoComponent, FacebookService, InitParams, UIParams, UIResponse } from 'ngx-facebook';
import { ClipboardService } from 'ngx-clipboard';
// import reframe from 'reframe.js';

declare var window: any;

@Component({
	selector: 'app-recorded',
	templateUrl: './recorded.component.html',
	styleUrls: ['./recorded.component.scss']
})
export class RecordedComponent implements OnInit {
	public video$: Observable<Partial<Broadcast>>;
	public youtubeId: string;

	public isLoading$ = new BehaviorSubject(true);

	public startVideo: boolean = false;

	public facebookLoaded: boolean = false;

	public showCopiedMessage: boolean = false;

	public showComments: boolean = false;

	public url: string = '';

	public YT: any;
	public player: any;
	public reframed: Boolean = false;

	@ViewChild(FBVideoComponent, { static: true })
	fbvideo: FBVideoComponent;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _broadcastsApi: BroadcastAPIService,
		private sanitizer: DomSanitizer,
		private fb: FacebookService,
		private _titleService: Title,
		private _clipboardService: ClipboardService
	) {}

	youtubeinit() {
		const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		window['onYouTubeIframeAPIReady'] = e => {
			this.YT = window['YT'];
			this.reframed = false;
			this.player = new window['YT'].Player('player', {
				videoId: this.youtubeId,
				events: {
					onStateChange: this.onPlayerStateChange.bind(this),
					onError: this.onPlayerError.bind(this),
					onReady: e => {
						if (!this.reframed) {
							this.reframed = true;
							// reframe(e.target.a);
						}
					}
				}
			});
		};
	}

	ngOnInit() {
		const initParams: InitParams = {
			appId: '1916404748485147',
			xfbml: true,
			version: 'v3.2'
		};

		// @todo remove when preroll active
		this.startVideo = true;

		this.fb.init(initParams).then(value => console.log(value));

		this.video$ = this._route.paramMap.pipe(
			filter(params => params.has('slug')),
			map(params => params.get('slug')),
			switchMap(slug =>
				this._broadcastsApi.getBroadcast(slug).pipe(
					tap(broadcast => {
						this.isLoading$.next(false);
						if (broadcast.live && !this.showComments) {
							this.toggleComments();
						}
						if (broadcast.title) this._titleService.setTitle(broadcast.title + ' - Rallyeradio');
						if (broadcast.slug && broadcast.category) {
							this.url =
								'https://rallyeradio.com/adasok/' + broadcast.category.slug + '/' + broadcast.slug;
						}
						if (broadcast.video_source === 'youtube') {
							this.youtubeId = broadcast.video_url;
							this.youtubeinit();
						}
					}),
					filter(post => !!post)
				)
			)
		);
	}

	public onPlayerStateChange(event) {
		// console.log(event);
	}

	public onPlayerError(event) {
		// console.log(event);
	}

	public getVideoUrl(video_url: string) {
		window.FB.XFBML.parse();
		return video_url;
	}

	public prerollEnded(ended: boolean) {
		if (ended) {
			this.startVideo = true;
			this.fbvideo.play();
		}
	}

	public shareFb() {
		const params: UIParams = {
			href: this.url,
			method: 'share'
		};

		this.fb
			.ui(params)
			.then((res: UIResponse) => console.log(res))
			.catch((e: any) => console.error(e));
	}

	public copy() {
		const copied = this._clipboardService.copyFromContent(this.url);
		if (copied) {
			this.showCopiedMessage = true;

			setTimeout(() => {
				this.showCopiedMessage = false;
			}, 5000);
		}
	}

	public toggleComments() {
		this.showComments = !this.showComments;
		if (this.showComments) {
			const initParams: InitParams = {
				appId: '1916404748485147',
				xfbml: true,
				version: 'v3.2'
			};

			this.fb.init(initParams).then(() => {
				this.facebookLoaded = true;
				window.FB.XFBML.parse();
			});
		}
	}
}

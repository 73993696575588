<div class="row">
	<div class="col-md-6">
		<a routerLink="/adasok/{{ broadcast.category.slug }}/{{ broadcast.slug || '' }}">
			<img
				[src]=" broadcast.featured_image_thumbnail || '/assets/img/street-default.png'"
				alt="{{ broadcast.title }}"
				class="img-fluid"
			/>
		</a>
	</div>
	<div class="col-md-6">
		<div class="holder">
			<div class="title">
				<a routerLink="/adasok/{{ broadcast.category.slug }}/{{ broadcast.slug || '' }}">
					<h2>{{ broadcast.title || '' }}</h2>
				</a>
			</div>
			<div class="intro my-3 eq" [innerHTML]="broadcast.lead || ''">	</div>
		</div>
	</div>
</div>

import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromPost from './post.reducers';

export { State as PostsState, PostState } from './post.reducers';

export interface State {
	posts: fromPost.State;
}

export const reducers: ActionReducerMap<State> = {
	posts: fromPost.reducer
};

const getState = createFeatureSelector<State>('cms');

export const getPostsState = createSelector(getState, (state: State) => state.posts);

<section class="bottom">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<h2>Az oldal nem található</h2>
				<p>Az oldal valószínűleg elköltözött vagy már nem elérhető.</p>
			</div>
		</div>
	</div>
</section>

<div class="swiper-container" *ngIf="images" [swiper]="config">
	<div class="swiper-wrapper">
		<div *ngFor="let item of images; let i = index" class="swiper-slide">
			<img [src]="item?.attributes?.image" class="img-fluid mx-auto" alt="" *ngIf="type === 'gallery'">
			<img [src]="item?.original" class="img-fluid mx-auto" alt="" *ngIf="type === 'post_gallery'">
		</div>
	</div>
	<div class="swiper-button-prev" *ngIf="images.length > 1">
		<i class="fas fa-chevron-left fa-3x"></i>
	</div>
	<div class="swiper-button-next" *ngIf="images.length > 1">
		<i class="fas fa-chevron-right fa-3x"></i>
	</div>
</div>
<button type="button" (click)="activeModal.close()" class="btn btn-link">
	<i class="fal fa-times fa-3x"></i>
</button>

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { mergeWith } from 'lodash-es';

import { Post, Collections, Collection, ResourceLoaderState } from '../../models/post.model';
import * as actions from '../actions/post.actions';

export interface PostState extends Partial<Post> {
	_loaderState: ResourceLoaderState;
}

export const initialPostState: PostState = {
	_loaderState: {
		loaded: false,
		loading: false,
		failed: false,
		partiallyLoaded: false
	}
};

const generateId = (post: PostState | Partial<Post>): string => `${post.slug}`;

export const adapter: EntityAdapter<PostState> = createEntityAdapter<PostState>({
	selectId: generateId,
	sortComparer: false
});

export interface State extends EntityState<PostState> {
	collections: Collections;
}

export const initialCollectionState: Collection = {
	pages: [],
	loadedEntities: [],
	_loaderState: {
		loaded: false,
		loading: false,
		failed: false
	}
};

export const initialState: State = adapter.getInitialState({
	collections: {}
});

export function reducer(state = initialState, action: actions.PostActions): State {
	if (!action) return state;

	const mergeWithCustomizer = (objValue, srcValue) => {
		if (Array.isArray(objValue)) {
			// return objValue.concat(srcValue).filter((elem, pos, arr) => arr.indexOf(elem) === pos);
			return srcValue;
		}
	};
	const getPrevEntityState = (id: string) => (state.entities[id] ? state.entities[id] : { ...initialPostState, id });
	const getPrevCollectionState = (collection: string) =>
		state.collections[collection] ? state.collections[collection] : { ...initialCollectionState };
	const transformPost = (post: Partial<Post>): Partial<Post> => ({ ...post, id: generateId(post) });

	switch (action.type) {
		case actions.POSTS_LOAD: {
			const { collection, page } = action.payload;
			return {
				...state,
				collections: {
					...state.collections,
					[collection]: {
						...getPrevCollectionState(collection),
						_loaderState: {
							...getPrevCollectionState(collection)._loaderState,
							loading: true
						}
					}
				}
			};
		}

		case actions.POSTS_LOAD_SUCCESS: {
			console.log(action.payload);
			const { collection, items, pagination } = action.payload;
			const posts = items.map(transformPost).map(post =>
				mergeWith(
					{},
					getPrevEntityState(generateId(post)),
					post,
					{
						_loaderState: {
							partiallyLoaded: true
						}
					},
					mergeWithCustomizer
				)
			);
			return adapter.upsertMany(posts, {
				...state,
				collections: {
					...state.collections,
					[collection]: {
						...getPrevCollectionState(collection),
						pages: {
							...getPrevCollectionState(collection).pages,
							[pagination.current_page]: posts.map(post => post.id)
						},
						loadedEntities: posts.map(post => post.id),
						pagination,
						_loaderState: {
							...getPrevCollectionState(collection)._loaderState,
							loaded: true,
							loading: false
						}
					}
				}
			});
		}

		case actions.POSTS_LOAD_FAILURE: {
			// const { collection } = action.payload;
			return {
				...state
				// collections: {
				// 	...state.collections,
				// 	[collection]: {
				// 		...getPrevCollectionState(collection),
				// 		_loaderState: {
				// 			...state.collections[collection]._loaderState,
				// 			loaded: true,
				// 			loading: false,
				// 		},
				// 	},
				// },
			};
		}

		case actions.POSTS_SET_FILTER: {
			const { collection, filter } = action.payload;
			return {
				...state,
				collections: {
					...state.collections,
					[collection]: {
						...initialCollectionState,
						filter,
						_loaderState: {
							failed: false,
							loaded: false,
							loading: false
						}
					}
				}
			};
		}

		case actions.POST_LOAD: {
			const { slug } = action.payload;
			const post = mergeWith(
				{ slug },
				getPrevEntityState(generateId(action.payload)),
				{
					_loaderState: {
						loading: true
					}
				},
				mergeWithCustomizer
			);
			return adapter.upsertOne(post, state);
		}

		case actions.POST_LOAD_SUCCESS: {
			const post = mergeWith(
				{},
				getPrevEntityState(generateId(action.payload.post)),
				transformPost(action.payload.post),
				{
					_loaderState: {
						loading: false,
						loaded: true,
						failed: false,
						partiallyLoaded: true
					}
				},
				mergeWithCustomizer
			);
			return adapter.upsertOne(post, state);
		}

		case actions.POST_LOAD_FAILURE: {
			const post = mergeWith(
				{},
				getPrevEntityState(generateId(action.payload)),
				{
					_loaderState: {
						loading: false,
						failed: true
					}
				},
				mergeWithCustomizer
			);
			return adapter.upsertOne(post, state);
		}

		default:
			return state;
	}
}

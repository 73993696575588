import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { VgOverlayPlayModule } from 'videogular2/compiled/overlay-play';
import { VgBufferingModule } from 'videogular2/compiled/buffering';

import { SHARED_DIRECTIVES } from './directives';
import { LoaderComponent } from './loader/loader.component';
import { SelectedPostComponent } from './posts/selected-post/selected-post.component';
import { SmallPostComponent } from './posts/small-post/small-post.component';
import { SmallPostImageComponent } from './posts/small-post-image/small-post-image.component';
import { VideoComponent } from './posts/selected-post/video/video.component';
import { SmallPostVideoComponent } from './posts/small-post-video/small-post-video.component';
import { TextBlockComponent } from './posts/blocks/text-block/text-block.component';
import { NotFoundComponent } from './components/404/404.component';
import { ImageBlockComponent } from './posts/blocks/image-block/image-block.component';
import { VideoBlockComponent } from './posts/blocks/video-block/video-block.component';
import { GalleryBlockComponent } from './posts/blocks/gallery-block/gallery-block.component';
import { PostGalleryBlockComponent } from './posts/blocks/post-gallery-block/post-gallery-block.component';
import { PrerollComponent } from './components/preroll/preroll.component';
import { LiveAudioComponent } from './components/live-audio/live-audio.component';
import { SmallPostSidebarComponent } from '@app/shared/posts/small-post-sidebar/small-post-sidebar.component';
import { AdComponent } from '@app/shared/components/ad/ad.component';
import { AlbumComponent } from './modals/album/album.component';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		VgCoreModule,
		VgControlsModule,
		VgOverlayPlayModule,
		VgBufferingModule,
		SwiperModule
	],
	declarations: [
		LoaderComponent,
		SelectedPostComponent,
		SmallPostComponent,
		SmallPostSidebarComponent,
		SmallPostImageComponent,
		VideoComponent,
		SmallPostVideoComponent,
		TextBlockComponent,
		ImageBlockComponent,
		VideoBlockComponent,
		GalleryBlockComponent,
		PostGalleryBlockComponent,
		PrerollComponent,
		AdComponent,
		LiveAudioComponent,
		NotFoundComponent,
		AlbumComponent,
		...SHARED_DIRECTIVES
	],
	exports: [
		LoaderComponent,
		SelectedPostComponent,
		SmallPostComponent,
		SmallPostSidebarComponent,
		SmallPostImageComponent,
		VideoComponent,
		SmallPostVideoComponent,
		TextBlockComponent,
		ImageBlockComponent,
		VideoBlockComponent,
		GalleryBlockComponent,
		PostGalleryBlockComponent,
		AlbumComponent,
		PrerollComponent,
		AdComponent,
		LiveAudioComponent,
		NotFoundComponent,
		...SHARED_DIRECTIVES
	],
	entryComponents: [AlbumComponent]
})
export class SharedModule {}

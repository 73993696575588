/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./video-block.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./video-block.component";
var styles_VideoBlockComponent = [i0.styles];
var RenderType_VideoBlockComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VideoBlockComponent, data: {} });
export { RenderType_VideoBlockComponent as RenderType_VideoBlockComponent };
function View_VideoBlockComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content.attributes.caption; _ck(_v, 1, 0, currVal_0); }); }
export function View_VideoBlockComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12 text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "embedly-card"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Embedly"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VideoBlockComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.content.attributes.caption; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.content.key, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.content.attributes.video, ""); _ck(_v, 4, 0, currVal_1); }); }
export function View_VideoBlockComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-video-block", [], null, null, null, View_VideoBlockComponent_0, RenderType_VideoBlockComponent)), i1.ɵdid(1, 114688, null, 0, i3.VideoBlockComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VideoBlockComponentNgFactory = i1.ɵccf("app-video-block", i3.VideoBlockComponent, View_VideoBlockComponent_Host_0, { content: "content" }, {}, []);
export { VideoBlockComponentNgFactory as VideoBlockComponentNgFactory };

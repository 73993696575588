<div class="row">
	<div class="col-md-6">
		<div class="aspect-ratio">
			<a routerLink="/hirek/{{ post.category.slug }}/{{ post.slug || '' }}">
				<img
					[src]=" post.featured_image_thumbnail || '/assets/img/street-default.png'"
					class="img-fluid"
					alt=""
				/>
			</a>
		</div>
	</div>
	<div class="col-md-6">
		<div class="holder">
			<div class="tag" [ngClass]="{'bg-orange': post.category.slug === 'rallye', 'bg-purple': post.category.slug === 'street'}">#{{ post.category.slug || 'rallye' }}</div>
			<div class="title">
				<a routerLink="/hirek/{{ post.category.slug }}/{{ post.slug || '' }}">
					<h2>{{ post.title || 'Hír' }}</h2>
				</a>
			</div>
			<div class="intro my-3 eq" [innerHTML]="post.lead"></div>
			<div class="date">
				<small>{{ post.published_ago || post.publish_date }}</small>
			</div>
		</div>
	</div>
</div>

<section class="bottom">
	<div class="container" *ngxLet="broadcasts$ | async as broadcasts">
		<div class="row">
			<div class="col-md-6"><h2>Adások</h2></div>
			<div class="col-md-6 align-self-center text-md-right mb-3 mb-md-0" *ngIf="broadcasts && broadcasts.items">
				<form [formGroup]="categoryForm">
					<div class="btn-group btn-group-toggle" ngbRadioGroup name="category-radio" formControlName="category">
						<label ngbButtonLabel class="btn btn-grey invert">
							<input ngbButton type="radio" [value]="''" /> ÖSSZES
						</label>
						<label ngbButtonLabel class="btn btn-grey invert">
							<input ngbButton type="radio" [value]="'rallye'" /> RALLYE
						</label>
						<label ngbButtonLabel class="btn btn-grey invert">
							<input ngbButton type="radio" [value]="'street'" /> STREET
						</label>
					</div>
				</form>
			</div>
		</div>
		<app-loader [isLoading]="true" *ngIf="isLoading"></app-loader>

		<ng-container *ngIf="broadcasts && broadcasts.items ">
			<div class="row" *ngIf="broadcasts.items && broadcasts.items['#selected']">
				<div class="col-md-12">
					<app-selected-post-video [broadcast]="broadcasts.items['#selected'][0]"></app-selected-post-video>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="broadcasts && broadcasts.items">
			<ng-container *ngFor="let monthPosts of broadcasts.items | keyvalue">
				<app-broadcasts-month [broadcasts]="monthPosts.value" [monthTitle]="monthPosts.key" *ngIf="monthPosts.key != '#selected'"></app-broadcasts-month>
			</ng-container>
		</ng-container>

		<div class="row mt-5" *ngIf="broadcasts && broadcasts.items && broadcasts.pagination.total < 1">
			<div class="col-12 col-md-5 mx-auto">
				Nincsen elérhető tartalom.
			</div>
		</div>

		<div class="row mt-5" *ngIf="broadcasts && broadcasts.items && broadcasts.pagination.total">
			<div class="col-md-12">
				<ngb-pagination
					class="d-flex justify-content-center"
					[maxSize]="5"
					[rotate]="true"
					[collectionSize]="broadcasts.pagination.total"
					[pageSize]="broadcasts.pagination.per_page"
					[(page)]="broadcasts.pagination.current_page"
					(pageChange)="onPageChange($event)"
				></ngb-pagination>
			</div>
		</div>
	</div>
</section>

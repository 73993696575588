<div class="container">
	<div class="row">
		<div class="col-md-6"><h2>Találatok</h2></div>
		<div class="col-md-6 align-self-center text-md-right">
			<div class="row">
				<div class="col">
					<form [formGroup]="categoryForm">
						<div class="btn-group btn-group-toggle" ngbRadioGroup name="category-radio" formControlName="category">
							<label ngbButtonLabel class="btn btn-grey">
								<input ngbButton type="radio" [value]="''" /> ÖSSZES
							</label>
							<label ngbButtonLabel class="btn btn-grey">
								<input ngbButton type="radio" [value]="'rallye'" /> RALLYE
							</label>
							<label ngbButtonLabel class="btn btn-grey">
								<input ngbButton type="radio" [value]="'street'" /> STREET
							</label>
						</div>
					</form>
				</div>
			</div>
			<div class="row mt-1">
				<div class="col">
					<form [formGroup]="typeForm">
						<div class="btn-group btn-group-toggle" ngbRadioGroup name="type-radio" formControlName="type">
							<label ngbButtonLabel class="btn btn-grey">
								<input ngbButton type="radio" [value]="'post'" /> HÍREK
							</label>
							<label ngbButtonLabel class="btn btn-grey">
								<input ngbButton type="radio" [value]="'broadcast'" /> ADÁSOK
							</label>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="col-12">
			<ng-container *ngxLet="posts$ | async as posts">
				<ng-container *ngxLet="broadcasts$ | async as broadcasts">
					<p><strong>{{ typeForm.get('type').value === 'post' ? (posts ? posts.pagination.total : 0) : (broadcasts ? broadcasts.pagination.total : 0) }}</strong> elemet találtunk a “{{ queryChanges$ | async }}” keresőszóra</p>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<ng-container *ngxLet="posts$ | async as posts">
		<ng-container *ngIf="posts && posts.pagination.total && typeForm.get('type').value === 'post'">
			<ng-container *ngFor="let monthPosts of posts.items | keyvalue">
				<app-news-month [posts]="monthPosts.value" [monthTitle]="monthPosts.key"></app-news-month>
			</ng-container>
		</ng-container>
		<div class="row mt-5" *ngIf="posts && posts.pagination.total && typeForm.get('type').value === 'post'">
			<div class="col-md-12">
				<ngb-pagination
					class="d-flex justify-content-center"
					[maxSize]="5"
					[rotate]="true"
					[collectionSize]="posts.pagination.total"
					[pageSize]="posts.pagination.per_page"
					[(page)]="posts.pagination.current_page"
					(pageChange)="onPageChange($event)"
				></ngb-pagination>
			</div>
		</div>
	</ng-container>

	<ng-container *ngxLet="broadcasts$ | async as broadcasts">
		<ng-container *ngIf="broadcasts && broadcasts.pagination.total && typeForm.get('type').value === 'broadcast'">
			<div class="row" *ngIf="broadcasts.items && broadcasts.items['#selected']">
				<div class="col-md-12">
					<app-selected-post-video [broadcast]="broadcasts.items['#selected'][0]"></app-selected-post-video>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="broadcasts && broadcasts.pagination.total">
			<ng-container *ngFor="let monthBroadcasts of broadcasts.items | keyvalue">
				<app-broadcasts-month [broadcasts]="monthBroadcasts.value" [monthTitle]="monthBroadcasts.key" *ngIf="monthBroadcasts.key != '#selected'"></app-broadcasts-month>
			</ng-container>
		</ng-container>

		<div class="row mt-5" *ngIf="broadcasts && broadcasts.pagination.total && typeForm.get('type').value === 'broadcast'">
			<div class="col-md-12">
				<ngb-pagination
					class="d-flex justify-content-center"
					[maxSize]="5"
					[rotate]="true"
					[collectionSize]="broadcasts.pagination.total"
					[pageSize]="broadcasts.pagination.per_page"
					[(page)]="broadcasts.pagination.current_page"
					(pageChange)="onPageChange($event)"
				></ngb-pagination>
			</div>
		</div>
	</ng-container>

</div>

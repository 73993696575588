<section class="bottom" *ngxLet="video$ | async as video">
	<div class="container" *ngIf="video">
		<div class="row live-video">
			<div class="col-md-8">
				<div class="row">
					<div class="col-12">
						<div class="embed-responsive" [ngClass]="{'h-0': !startVideo}" *ngIf="video.video_source === 'facebook'">
							<fb-video [href]="getVideoUrl(video.video_url)" *ngIf="video.video_url"></fb-video>
						</div>
						<div class="embed-responsive embed-responsive-16by9" *ngIf="video.video_source === 'youtube'">
							<div class="embed-responsive-item" id="player"></div>
						</div>
						<!-- <div>
							<app-preroll-video (ended)="prerollEnded($event)" *ngIf="!startVideo"></app-preroll-video>
						</div> -->
						<div class="tag" *ngIf="video.category" [ngClass]="{'bg-orange': video.category.slug === 'rallye', 'bg-purple': video.category.slug === 'street'}">#{{ video.category.slug || 'rallye' }}</div>
						<h2>{{ video.title }}</h2>
						<p>
							<small [innerHTML]="video.lead"></small>
						</p>
						<div>
							<h3>MEGOSZTÁS</h3>
							<a href="https://twitter.com/intent/tweet?text={{ video.title }}%20-%20RallyeRadio&url={{ url }}" target="_blank">
								<div class="socials">
									<div class="social-image twitter"></div>
									<p>Twitter</p>
								</div>
							</a>
							<div class="socials" (click)="shareFb()">
								<div class="social-image facebook"></div>
								<p>Facebook</p>
							</div>
							<!-- <div class="socials">
								<div class="social-image email"></div>
								<p>Email</p>
							</div> -->
							<div class="socials" (click)="copy()">
								<div class="social-image link"></div>
								<p>Link <span class="text-success" *ngIf="showCopiedMessage">Másolva</span></p>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-4" *ngIf="!video.live">
					<div class="col-12">
						<button class="btn btn-lg btn-grey invert comment-button" (click)="toggleComments()">
							<span>Hozzászólások</span>
							<img src="/assets/img/chevron-up.svg" class="chevron" [ngClass]="{'rotated': showComments}" />
						</button>
					</div>
					<div class="col-12" *ngIf="facebookLoaded && showComments">
						<fb-comments [href]="video.video_url" colorscheme="dark" width="100%"></fb-comments>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="row mb-4" *ngIf="video.live">
					<div class="col-12">
						<button class="btn btn-lg btn-grey invert comment-button" (click)="toggleComments()">
							<span>Hozzászólások</span>
							<img src="/assets/img/chevron-up.svg" class="chevron" [ngClass]="{'rotated': showComments}" />
						</button>
					</div>
					<div class="col-12" *ngIf="facebookLoaded && showComments">
						<fb-comments [href]="video.video_url" colorscheme="dark" width="100%"></fb-comments>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<h4 *ngIf="video.latest_broadcasts">Korábbi adások</h4>
					</div>
					<div class="col-12" *ngFor="let videoBroadcast of video.latest_broadcasts">
						<app-small-post-video [broadcast]="videoBroadcast"></app-small-post-video>
					</div>
				</div>
				<!--<div class="post-holder"><app-small-post-video [title]="'Vecsési Rallye'"></app-small-post-video></div>
				<div class="post-holder">
					<app-small-post-video [title]="'StreetPodcast - Episode 123'"></app-small-post-video>
				</div>-->
			</div>
		</div>
	</div>
</section>

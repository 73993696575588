<ng-container *ngxLet="page$ | async as page">
<section class="bottom" *ngIf="page; else loading">
	<div class="header-image full-cover" [ngStyle]="{'background-image': 'url(' + (page.cover_image || page.featured_image) + ')'}" *ngIf="page.type === 'full_cover' && (page.cover_image || page.featured_image)"></div>
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2>
					{{ page.title }}
				</h2>
				<p class="date">{{ page.publish_date }} - {{ page.author.name }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<ng-container *ngIf="page.type === 'cover' && (page.cover_image || page.featured_image)">
				<div class="header-image" [ngStyle]="{'background-image': 'url(' + (page.cover_image || page.featured_image) + ')'}"></div>
				<small *ngIf="page.cover_credits">{{ page.cover_credits }}</small>
				</ng-container>
				<div class="row">
					<div class="col-md-3">
						<h3>MEGOSZTÁS</h3>
						<a href="https://twitter.com/intent/tweet?text={{ page.title }}%20-%20RallyeRadio&url={{ url }}" target="_blank">
						<div class="socials">
							<div class="social-image twitter"></div>
							<p>Twitter</p>
						</div>
						</a>
						<div class="socials" (click)="shareFb()">
							<div class="social-image facebook"></div>
							<p>Facebook</p>
						</div>
						<!--<div class="socials">
							<div class="social-image email"></div>
							<p>Email</p>
						</div> -->
						<div class="socials" (click)="copy()" >
							<div class="social-image link"></div>
							<p>Link <span class="text-success" *ngIf="showCopiedMessage">Másolva</span></p>
						</div>
						<!-- <button type="button" class="btn btn-block btn-donate mt-3">Támogatás</button> -->
					</div>
					<div class="col-md-9">
						<div class="post-lead" [innerHTML]="page.lead"></div>
						<div class="post-body">
							<ng-container *ngFor="let block of page.content">
								<app-text-block [content]="block" *ngIf="block.layout === 'text'"></app-text-block>
								<app-image-block [content]="block" *ngIf="block.layout === 'image'"></app-image-block>
								<app-video-block [content]="block" *ngIf="block.layout === 'video'"></app-video-block>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
</ng-container>

<ng-template #loading>
	<!-- loading... -->
</ng-template>

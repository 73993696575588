<section>
	<div class="container">
		<div class="row">
			<div class="col-md-6 align-self-center text-center text-md-left">
				<p class="copyright">
					<a routerLink="/oldalak/impresszum">Sport Live Media Kft - Minden jog fenntartva © {{ date.getFullYear() }}</a>
				</p>
			</div>
			<div class="col-md-6 text-center text-md-right">
				<nav class="nav flex-column flex-sm-row">
					<ng-container *ngxLet="settings$ | async as settings">
						<ng-container *ngIf="settings">
							<ng-container *ngFor="let footerlink of settings.footer_links">
								<a *ngIf="footerlink.layout ==='page_layout'" class="flex-sm-fill text-sm-center nav-link" routerLink="/oldalak/{{ footerlink.attributes.page.slug }}" routerLinkActive="active">
									{{ footerlink.attributes.page.title }}
								</a>
								<a *ngIf="footerlink.layout ==='link_layout'" class="flex-sm-fill text-sm-center nav-link" target="_blank" href="{{ footerlink.attributes.link_url }}">
									{{ footerlink.attributes.link_title }}
								</a>
							</ng-container>
						</ng-container>
					</ng-container>
				</nav>
			</div>
		</div>
	</div>
</section>

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';

import { PostsAPIService } from '@app/cms/services/post.service';
import * as PostActions from '@app/cms/store/actions/post.actions';

import { switchMap, mergeMap, catchError, map, first } from 'rxjs/operators';
import { State } from '@app/cms/store/reducers';
import { getPostsFilter } from '@app/cms/store/selectors';
// import { tag } from 'rxjs-spy/operators/tag';

@Injectable()
export class PostEffects {
	constructor(private actions$: Actions, private store$: Store<State>, private postsAPIService: PostsAPIService) {}

	// @Effect()
	// setFilter$: Observable<Action> = this.actions$
	// 	.ofType(PostActions.POSTS_SET_FILTER).pipe(
	// 		tap(x => console.log('effect setFilter$ payload = ', x)),
	// 		map((action: PostActions.PostsSetFilterAction) => action.payload.collection),
	// 		mergeMap(collection => [
	// 			new PostActions.PostsLoadAction(collection),
	// 		]),
	// 	);

	@Effect()
	loadPosts$: Observable<Action> = this.actions$.pipe(
		ofType(PostActions.POSTS_LOAD),
		map((action: PostActions.PostsLoadAction) => action.payload),
		mergeMap(({ collection, page, count = null }) =>
			this.store$.select(getPostsFilter(collection)).pipe(
				first(),
				map(filter => ({ collection, filter, page, count }))
			)
		),
		mergeMap(({ collection, filter, page, count }) => {
			return this.postsAPIService.getPosts({ filter, page, count }).pipe(
				mergeMap(result => [new PostActions.PostsLoadSuccessAction({ ...result, collection })]),
				catchError(error => {
					console.log('effect error', error);
					return of(new PostActions.PostsLoadFailureAction({ collection }));
				})
			);
		})
		// tag('effect-loadposts'),
	);

	@Effect()
	loadPostDetails$: Observable<Action> = this.actions$.pipe(
		ofType(PostActions.POST_LOAD),
		map((action: PostActions.PostLoadAction) => action.payload),
		switchMap(({ slug, options }) => {
			return this.postsAPIService.getPost(slug, options).pipe(
				mergeMap(post => [new PostActions.PostLoadSuccessAction({ post })]),
				catchError(error => {
					console.log('effect error', error);
					return of(new PostActions.PostLoadFailureAction({ slug }));
				})
			);
		})
	);
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live-audio.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/videogular2/compiled/src/core/vg-player/vg-player.ngfactory";
import * as i3 from "videogular2/compiled/src/core/services/vg-api";
import * as i4 from "videogular2/compiled/src/core/services/vg-fullscreen-api";
import * as i5 from "videogular2/compiled/src/core/services/vg-controls-hidden";
import * as i6 from "videogular2/compiled/src/core/vg-player/vg-player";
import * as i7 from "videogular2/compiled/src/core/vg-media/vg-media";
import * as i8 from "@angular/common";
import * as i9 from "./live-audio.component";
var styles_LiveAudioComponent = [i0.styles];
var RenderType_LiveAudioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LiveAudioComponent, data: {} });
export { RenderType_LiveAudioComponent as RenderType_LiveAudioComponent };
function View_LiveAudioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "source", [], [[8, "src", 4], [8, "type", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.src; var currVal_1 = _v.context.$implicit.type; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_LiveAudioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 46, "div", [["class", "radio-player"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "button", [["class", "play"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.playRadio() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 8, ":svg:svg", [["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(12, 0, null, null, 0, ":svg:path", [["class", "button"], ["d", "M8 5v14l11-7z"], ["fill", "#FFF"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 0, ":svg:path", [["d", "M0 0h24v24H0z"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(19, 0, null, null, 11, "button", [["class", "stop"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopRadio() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(21, 0, null, null, 8, ":svg:svg", [["height", "24"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(23, 0, null, null, 5, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(25, 0, null, null, 0, ":svg:path", [["d", "M0 0h24v24H0z"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(27, 0, null, null, 0, ":svg:path", [["class", "button"], ["d", "M6 6h12v12H6z"], ["fill", "#FFF"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(32, 0, null, null, 13, "vg-player", [["style", "height:0;width:0;"]], [[2, "fullscreen", null], [2, "native-fullscreen", null], [2, "controls-hidden", null], [4, "z-index", null]], [[null, "onPlayerReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onPlayerReady" === en)) {
        var pd_0 = (_co.onPlayerReady($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VgPlayer_0, i2.RenderType_VgPlayer)), i1.ɵprd(512, null, i3.VgAPI, i3.VgAPI, []), i1.ɵprd(512, null, i4.VgFullscreenAPI, i4.VgFullscreenAPI, []), i1.ɵprd(512, null, i5.VgControlsHidden, i5.VgControlsHidden, []), i1.ɵdid(36, 1228800, null, 1, i6.VgPlayer, [i1.ElementRef, i3.VgAPI, i4.VgFullscreenAPI, i5.VgControlsHidden], null, { onPlayerReady: "onPlayerReady" }), i1.ɵqud(603979776, 1, { medias: 1 }), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(39, 0, [["media", 1]], 0, 5, "audio", [["id", "myAudio"], ["preload", "auto"]], null, null, null, null, null)), i1.ɵdid(40, 212992, [[1, 4]], 0, i7.VgMedia, [i3.VgAPI, i1.ChangeDetectorRef], { vgMedia: [0, "vgMedia"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveAudioComponent_2)), i1.ɵdid(43, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = i1.ɵnov(_v, 39); _ck(_v, 40, 0, currVal_7); var currVal_8 = _co.sources; _ck(_v, 43, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.isPlaying; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_co.isPlaying; _ck(_v, 19, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 36).isFullscreen; var currVal_4 = i1.ɵnov(_v, 36).isNativeFullscreen; var currVal_5 = i1.ɵnov(_v, 36).areControlsHidden; var currVal_6 = i1.ɵnov(_v, 36).zIndex; _ck(_v, 32, 0, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_LiveAudioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LiveAudioComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.radio_on; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LiveAudioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-live-audio", [], null, null, null, View_LiveAudioComponent_0, RenderType_LiveAudioComponent)), i1.ɵdid(1, 114688, null, 0, i9.LiveAudioComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveAudioComponentNgFactory = i1.ɵccf("app-live-audio", i9.LiveAudioComponent, View_LiveAudioComponent_Host_0, { settings: "settings" }, { ended: "ended" }, []);
export { LiveAudioComponentNgFactory as LiveAudioComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./programs-month.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../program-card/program-card.component.ngfactory";
import * as i3 from "../program-card/program-card.component";
import * as i4 from "@angular/common";
import * as i5 from "./programs-month.component";
import * as i6 from "../../cms/services/cms.service";
import * as i7 from "@angular/router";
var styles_ProgramsMonthComponent = [i0.styles];
var RenderType_ProgramsMonthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramsMonthComponent, data: {} });
export { RenderType_ProgramsMonthComponent as RenderType_ProgramsMonthComponent };
function View_ProgramsMonthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-program-card", [], null, null, null, i2.View_ProgramCardComponent_0, i2.RenderType_ProgramCardComponent)), i1.ɵdid(3, 114688, null, 0, i3.ProgramCardComponent, [], { program: [0, "program"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ProgramsMonthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramsMonthComponent_1)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.programs; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getMonthName(_co.monthTitle); _ck(_v, 5, 0, currVal_0); }); }
export function View_ProgramsMonthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-programs-month", [], null, null, null, View_ProgramsMonthComponent_0, RenderType_ProgramsMonthComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProgramsMonthComponent, [i6.CMSService, i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgramsMonthComponentNgFactory = i1.ɵccf("app-programs-month", i5.ProgramsMonthComponent, View_ProgramsMonthComponent_Host_0, { programs: "programs", monthTitle: "monthTitle" }, {}, []);
export { ProgramsMonthComponentNgFactory as ProgramsMonthComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./album.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-swiper-wrapper";
import * as i4 from "./album.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../cms/services/cms.service";
var styles_AlbumComponent = [i0.styles];
var RenderType_AlbumComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlbumComponent, data: {} });
export { RenderType_AlbumComponent as RenderType_AlbumComponent };
function View_AlbumComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid mx-auto"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.attributes == null) ? null : _v.parent.context.$implicit.attributes.image)); _ck(_v, 0, 0, currVal_0); }); }
function View_AlbumComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["class", "img-fluid mx-auto"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.original); _ck(_v, 0, 0, currVal_0); }); }
function View_AlbumComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "swiper-slide"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "gallery"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.type === "post_gallery"); _ck(_v, 6, 0, currVal_1); }, null); }
function View_AlbumComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "swiper-button-prev"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-chevron-left fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, null); }
function View_AlbumComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "swiper-button-next"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-chevron-right fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, null); }
function View_AlbumComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "swiper-container"]], null, null, null, null, null)), i1.ɵdid(1, 5128192, null, 0, i3.SwiperDirective, [i1.PLATFORM_ID, i1.NgZone, i1.ElementRef, i1.KeyValueDiffers, [2, i3.SWIPER_CONFIG]], { config: [0, "config"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "swiper-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_5)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_6)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.images; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.images.length > 1); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.images.length > 1); _ck(_v, 13, 0, currVal_3); }, null); }
export function View_AlbumComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlbumComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-link"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fal fa-times fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.images; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AlbumComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-album", [], null, null, null, View_AlbumComponent_0, RenderType_AlbumComponent)), i1.ɵdid(1, 114688, null, 0, i4.AlbumComponent, [i5.NgbActiveModal, i2.PlatformLocation, i6.CMSService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlbumComponentNgFactory = i1.ɵccf("app-album", i4.AlbumComponent, View_AlbumComponent_Host_0, { images: "images", type: "type" }, {}, []);
export { AlbumComponentNgFactory as AlbumComponentNgFactory };

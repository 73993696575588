import { Component, OnInit } from '@angular/core';
import { filter, finalize, map, skip, take, first, tap, pluck } from 'rxjs/operators';
import { CMSService, Pagination, PostState, Program } from '@app/cms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BroadcastAPIService } from '@app/cms/services/broadcast.service';
import { Broadcast } from '@app/cms/models/broadcast.model';
import { ProgramAPIService } from '@app/cms/services/program.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	public isLoading: boolean = true;

	public posts$: Observable<PostState[]>;

	public rallyePosts$: Observable<PostState[]>;
	public streetPosts$: Observable<PostState[]>;

	public mainPosts$: Observable<PostState[]>;

	public featuredPost$: Observable<PostState>;

	public broadcasts$: Observable<Partial<Broadcast>[]>;
	public programs$: Observable<Partial<Program>[]>;

	public featuredBroadcast$: Observable<Partial<Broadcast>>;

	public podcastBoadcasts$: Observable<Partial<Broadcast>[]>;
	public videoBroadcasts$: Observable<Partial<Broadcast>[]>;

	constructor(
		private _cms: CMSService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _broadcastsApi: BroadcastAPIService,
		private _programsApi: ProgramAPIService
	) {
		this.isLoading = true;
	}

	ngOnInit() {
		this.posts$ = this._getPosts();

		this.featuredPost$ = this.posts$.pipe(
			tap(posts => {
				if (posts.length) this.isLoading = false;
			}),
			map(posts => posts[0])
		);

		this.mainPosts$ = this.posts$.pipe(map(posts => posts.filter((post, index) => index > 0 && index < 4)));

		this.rallyePosts$ = this._getPostsByCategory('rallye').pipe(take(4));
		this.streetPosts$ = this._getPostsByCategory('street').pipe(take(4));

		this.broadcasts$ = this._getBroadcasts();

		this.programs$ = this._getPrograms();

		this.featuredBroadcast$ = this.broadcasts$.pipe(map(broadcasts => broadcasts[0]));

		this.podcastBoadcasts$ = this.broadcasts$.pipe(
			map(broadcasts => broadcasts.filter((broadcast, index) => index > 0 && broadcast.type === 'podcast')),
			take(4)
		);

		this.videoBroadcasts$ = this.broadcasts$.pipe(
			map(broadcasts => broadcasts.filter((broadcast, index) => index > 0 && broadcast.type === 'video')),
			take(4)
		);
	}

	private _getPosts(): Observable<PostState[]> {
		return this._cms.getPosts('post', 1, {
			loadPostsImmediately: true,
			count: 20
		});
	}

	private _getBroadcasts(): Observable<Partial<Broadcast>[]> {
		return this._broadcastsApi
			.getBroadcasts({
				count: 40
			})
			.pipe(
				/*tap(({items, pagination}) => {
				console.log('items', items);
				console.log('pagination', pagination);
			}),*/
				pluck('items')
			);
	}

	private _getPostsByCategory(category: string): Observable<PostState[]> {
		return this.posts$.pipe(
			map(posts => posts.filter((post, index) => index > 3 && post.category.slug === category))
		);
	}

	private _getPrograms(): Observable<Partial<Program>[]> {
		return this._programsApi
			.getPrograms({
				count: 5
			})
			.pipe(
				/*tap(({items, pagination}) => {
				console.log('items', items);
				console.log('pagination', pagination);
			}),*/
				pluck('items')
			);
	}
}

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CMSService, PostState } from '@app/cms';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { FacebookService, InitParams, UIParams, UIResponse, FBCommentsComponent } from 'ngx-facebook';
import { ClipboardService } from 'ngx-clipboard';

declare var window: any;

@Component({
	selector: 'app-post',
	templateUrl: './post.component.html',
	styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
	public tag: string;

	public post$: Observable<PostState>;
	public isLoading$ = new BehaviorSubject(true);

	public showComments: boolean = false;

	public url: string = '';

	public facebookLoaded: boolean = false;

	public showCopiedMessage: boolean = false;

	constructor(
		private _cms: CMSService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _titleService: Title,
		private fb: FacebookService,
		private _clipboardService: ClipboardService
	) {}

	ngOnInit() {
		const initParams: InitParams = {
			appId: '1916404748485147',
			xfbml: true,
			version: 'v3.2'
		};

		this.fb.init(initParams).then(() => {
			this.facebookLoaded = true;
			window.FB.XFBML.parse();
		});

		this.post$ = this._route.paramMap.pipe(
			filter(params => params.has('slug')),
			map(params => params.get('slug')),
			switchMap(slug =>
				this._cms.getPost(slug, { post: 'post' }).pipe(
					tap(post => {
						// console.log('post._loaderState', post._loaderState);
						this.isLoading$.next(post && post._loaderState.loading);
					}),
					filter(post => !!post),
					tap(post => {
						if (post._loaderState.failed) this._router.navigate(['/404'], { skipLocationChange: true });
						if (post.title) this._titleService.setTitle(post.title + ' - StreetRadio');
						if (post.slug && post.category) {
							this.url = 'https://streetradio.hu/hirek/' + post.category.slug + '/' + post.slug;
						}
					}),
					filter(post => !!post && post._loaderState.loaded)
				)
			)
		);
	}

	public shareFb() {
		const params: UIParams = {
			href: this.url,
			method: 'share'
		};

		this.fb
			.ui(params)
			.then((res: UIResponse) => console.log(res))
			.catch((e: any) => console.error(e));
	}

	public copy() {
		const copied = this._clipboardService.copyFromContent(this.url);
		if (copied) {
			this.showCopiedMessage = true;

			setTimeout(() => {
				this.showCopiedMessage = false;
			}, 5000);
		}
	}

	public toggleComments() {
		this.showComments = !this.showComments;
		if (this.showComments) {
			const initParams: InitParams = {
				appId: '1916404748485147',
				xfbml: true,
				version: 'v3.2'
			};

			this.fb.init(initParams).then(() => {
				this.facebookLoaded = true;
				window.FB.XFBML.parse();
			});
		}
	}
}

<div class="radio-player" *ngIf="settings.radio_on">
	<div class="title"><span>{{ title }}</span></div>
	<button class="play" (click)="playRadio()" [disabled]="isPlaying">
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path class="button" fill="#FFF" d="M8 5v14l11-7z"/>
				<path d="M0 0h24v24H0z"/>
			</g>
		</svg>
	</button>
	<button class="stop" (click)="stopRadio()" [disabled]="!isPlaying">
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g fill="none" fill-rule="evenodd">
				<path d="M0 0h24v24H0z"/>
				<path class="button" fill="#FFF" d="M6 6h12v12H6z"/>
			</g>
		</svg>
	</button>
	<vg-player (onPlayerReady)="onPlayerReady($event)" style="height:0;width:0;">
		<audio #media [vgMedia]="media" id="myAudio" preload="auto">
			<source *ngFor="let audio of sources" [src]="audio.src" [type]="audio.type">
		</audio>
	</vg-player>
</div>

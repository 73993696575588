<div class="holder">
	<div class="tag" [ngClass]="{'bg-orange': post.category.slug === 'rallye', 'bg-purple': post.category.slug === 'street'}">#{{ post.category.slug}}</div>
	<div class="title eq">
		<a routerLink="/hirek/{{ post.category.slug }}/{{ post.slug }}">
			<h2>{{ post.title || 'Hír' }}</h2>
		</a>
	</div>
	<div class="date">
		<small>{{ post.published_ago || post.published_date }}</small>
	</div>
</div>

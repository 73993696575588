/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./small-post.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "./small-post.component";
var styles_SmallPostComponent = [i0.styles];
var RenderType_SmallPostComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SmallPostComponent, data: {} });
export { RenderType_SmallPostComponent as RenderType_SmallPostComponent };
export function View_SmallPostComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "holder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "tag"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "bg-orange": 0, "bg-purple": 1 }), (_l()(), i1.ɵted(6, null, ["#", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "title eq"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(21, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tag"; var currVal_1 = _ck(_v, 5, 0, (_co.post.category.slug === "rallye"), (_co.post.category.slug === "street")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵinlineInterpolate(2, "/hirek/", _co.post.category.slug, "/", _co.post.slug, ""); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.post.category.slug; _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = (_co.post.title || "H\u00EDr"); _ck(_v, 14, 0, currVal_6); var currVal_7 = (_co.post.published_ago || _co.post.published_date); _ck(_v, 21, 0, currVal_7); }); }
export function View_SmallPostComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-small-post", [], null, null, null, View_SmallPostComponent_0, RenderType_SmallPostComponent)), i1.ɵdid(1, 114688, null, 0, i4.SmallPostComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SmallPostComponentNgFactory = i1.ɵccf("app-small-post", i4.SmallPostComponent, View_SmallPostComponent_Host_0, { post: "post" }, {}, []);
export { SmallPostComponentNgFactory as SmallPostComponentNgFactory };

<section class="bottom">
	<div class="container">
		<div class="row live-video">
			<div class="col-md-8">
				<div class="live">
					<img
						src="/assets/img/street-default.png"
						class="img-fluid"
					/>
					<div class="live-breadcrumb">ÉLŐ</div>
				</div>
				<div class="tag bg-purple">#{{ tag || 'rallye' }}</div>
				<h2>Még hogy Svájcban nincs autóverseny?</h2>
				<p>
					<small
						>A 2017/18-as Formula-e szezon egyik állomása Zürichben volt, és ezzel kapcsolatban több helyen
						is felmerült, hogy évtizedek óta ez az első autóverseny Svájcban. A körpályás – gyorsasági –
						versenyek tekintetében valóban volt egy hosszabb szünet, de más szakágak, versenyek igenis
						…</small
					>
				</p>
				<div>
					<h3>MEGOSZTÁS</h3>
					<div class="socials">
						<div class="social-image twitter"></div>
						<p>Twitter</p>
					</div>
					<div class="socials">
						<div class="social-image facebook"></div>
						<p>Facebook</p>
					</div>
					<div class="socials">
						<div class="social-image email"></div>
						<p>Email</p>
					</div>
					<div class="socials">
						<div class="social-image link"></div>
						<p>Link</p>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<h4>Korábbi adások</h4>
				<div class="post-holder"><app-small-post-video [title]="'Vecsési Rallye'"></app-small-post-video></div>
				<div class="post-holder">
					<app-small-post-video [title]="'StreetPodcast - Episode 123'"></app-small-post-video>
				</div>
			</div>
		</div>
	</div>
</section>

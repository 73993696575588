import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { Ad, Ads } from '@app/cms';

@Injectable()
export class AdAPIService {
	private readonly endpoint = '/api/ards';

	constructor(private http: HttpClient) {}

	public getAds(page: string): Observable<Ads> {
		const endpoint = this.endpoint + '/' + page;
		return this.http.get<{ data: Ads }>(endpoint).pipe(pluck('data'));
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { CMSService, Program } from '@app/cms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-programs-month',
	templateUrl: './programs-month.component.html',
	styleUrls: ['./programs-month.component.scss']
})
export class ProgramsMonthComponent implements OnInit {
	@Input()
	public programs: Partial<Program>[];
	@Input()
	public monthTitle: string;

	constructor(private _cms: CMSService, private _route: ActivatedRoute, private _router: Router) {}

	ngOnInit() {}

	public getMonthName(month: string) {
		const monthSplitted = month.split('#');

		if (monthSplitted[1]) {
			return monthSplitted[1];
		}

		return month;
	}
}

import { Component } from '@angular/core';

@Component({
	selector: 'app-page-404',
	styleUrls: ['404.component.scss'],
	templateUrl: '404.component.html'
})
export class NotFoundComponent {
	constructor() {}
}

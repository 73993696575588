import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, effects } from './store';

import { PostsAPIService } from './services/post.service';

import { CMSService } from './services/cms.service';
import { BroadcastAPIService } from './services/broadcast.service';
import { ProgramAPIService } from '@app/cms/services/program.service';
import { SettingsAPIService } from '@app/cms/services/settings.service';
import { AdAPIService } from '@app/cms/services/ad.service';

@NgModule({
	imports: [StoreModule.forFeature('cms', reducers), EffectsModule.forFeature(effects)],
	declarations: [],
	entryComponents: [],
	exports: [],
	providers: [PostsAPIService, BroadcastAPIService, ProgramAPIService, CMSService, SettingsAPIService, AdAPIService]
})
export class CMSModule {}

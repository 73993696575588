<section class="bottom">
	<div class="container" *ngxLet="posts$ | async as posts">
		<div class="row">
			<div class="col-md-6"><h2 class="category-title">{{ _categoryFormChanges$ | async }} Hírek</h2></div>
			<div class="col-md-6 align-self-center text-md-right">
				<form [formGroup]="categoryForm">
					<div class="btn-group btn-group-toggle" ngbRadioGroup name="category-radio" formControlName="category">
						<label ngbButtonLabel class="btn btn-grey">
							<input ngbButton type="radio" [value]="''" /> ÖSSZES
						</label>
						<label ngbButtonLabel class="btn btn-grey">
							<input ngbButton type="radio" [value]="'rallye'" /> RALLYE
						</label>
						<label ngbButtonLabel class="btn btn-grey">
							<input ngbButton type="radio" [value]="'street'" /> STREET
						</label>
					</div>
				</form>
			</div>
		</div>

		<app-loader [isLoading]="true" *ngIf="isLoading"></app-loader>

		<ng-container *ngIf="posts">
			<ng-container *ngFor="let monthPosts of posts.items | keyvalue:reverseKeyOrder">
				<app-news-month [posts]="monthPosts.value" [monthTitle]="monthPosts.key"></app-news-month>
			</ng-container>
		</ng-container>
		<div class="row mt-5">
			<div class="col-md-12" *ngIf="posts">
				<ngb-pagination
					class="d-flex justify-content-center"
					[maxSize]="5"
					[rotate]="true"
					[collectionSize]="posts.pagination.total"
					[pageSize]="posts.pagination.per_page"
					[(page)]="posts.pagination.current_page"
					(pageChange)="onPageChange($event)"
				></ngb-pagination>
			</div>
		</div>
		<div class="row mt-4">
			<app-ad [page]="'news'" [location]="'news_list'"></app-ad>
		</div>
	</div>
</section>

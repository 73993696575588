<ng-container *ngxLet="post$ | async as post">
<section class="bottom" *ngIf="post; else loading">
	<div class="header-image full-cover" [ngStyle]="{'background-image': 'url(' + (post.cover_image || post.featured_image) + ')'}" *ngIf="post.type === 'full_cover' && (post.cover_image || post.featured_image)"></div>
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="tag"  [ngClass]="{'bg-orange': post.category.slug === 'rallye', 'bg-purple': post.category.slug === 'street'}">
					<a routerLink="/hirek/{{ post.category.slug }}">
						#{{ post.category.slug }}
					</a>
				</div>
				<h2>
					{{ post.title }}
				</h2>
				<p class="date">{{ post.publish_date }} - {{ post.author.name }}</p>
			</div>
		</div>
		<div class="row">
			<div class="col-md-9">
				<ng-container *ngIf="post.type === 'cover' && (post.cover_image || post.featured_image)">
				<div class="header-image" [ngStyle]="{'background-image': 'url(' + (post.cover_image || post.featured_image) + ')'}"></div>
				<small>
					<span *ngIf="post.cover_credits">{{ post.cover_credits }}</span>&nbsp;
				</small>
				</ng-container>
				<div class="row">
					<div class="col-md-3">
						<h3>MEGOSZTÁS</h3>
						<a href="https://twitter.com/intent/tweet?text={{ post.title }}%20-%20RallyeRadio&url={{ url }}" target="_blank">
						<div class="socials">
							<div class="social-image twitter"></div>
							<p>Twitter</p>
						</div>
						</a>
						<div class="socials" (click)="shareFb()">
							<div class="social-image facebook"></div>
							<p>Facebook</p>
						</div>
						<!--<div class="socials">
							<div class="social-image email"></div>
							<p>Email</p>
						</div> -->
						<div class="socials" (click)="copy()" >
							<div class="social-image link"></div>
							<p>Link <span class="text-success" *ngIf="showCopiedMessage">Másolva</span></p>
						</div>
						<!-- <button type="button" class="btn btn-block btn-donate mt-3">Támogatás</button> -->
					</div>
					<div class="col-md-9">
						<div class="post-lead" [innerHTML]="post.lead"></div>
						<div class="post-body">
							<ng-container *ngFor="let block of post.content">
								<app-text-block [content]="block" *ngIf="block.layout === 'text'"></app-text-block>
								<app-image-block [content]="block" *ngIf="block.layout === 'image'"></app-image-block>
								<app-video-block [content]="block" *ngIf="block.layout === 'video'"></app-video-block>
								<app-gallery-block [content]="block" *ngIf="block.layout === 'gallery'"></app-gallery-block>
								<app-post-gallery-block [content]="block" *ngIf="block.layout === 'post_gallery'"></app-post-gallery-block>
							</ng-container>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-9 offset-md-3 mb-2">
						<button class="btn btn-lg btn-grey invert" id="comment-button" (click)="toggleComments()">
							<span>Hozzászólások</span>
							<img src="/assets/img/chevron-up.svg" class="chevron" [ngClass]="{'rotated': showComments}" />
						</button>
					</div>
					<div class="col-md-9 offset-md-3" *ngIf="facebookLoaded && showComments">
						<fb-comments [href]="url" width="100%"></fb-comments>
					</div>
				</div>
			</div>
			<div class="col-md-3">
				<div class="timeline">
					<div class="header">
						<p>TOP CIKKEK</p>
					</div>
					<app-small-post-sidebar [post]="topPost" *ngFor="let topPost of post.top_posts"></app-small-post-sidebar>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12"><h2 class="mt-5">Ajánlott cikkek</h2></div>
		</div>
		<div class="row mb-3">
			<div class="col-md-3" *ngFor="let postSuggestion of post.post_suggestions">
				<app-small-post-image [post]="postSuggestion"></app-small-post-image>
			</div>
			<div class="col-md-6" *ngIf="post.post_suggestions.length < 3">
				<app-ad [page]="'post'" [location]="'news_suggestions'"></app-ad>
			</div>
		</div>
		<div class="row mb-3">
			<app-ad [page]="'post'" [location]="'news_bottom'"></app-ad>
		</div>
	</div>
</section>
</ng-container>

<ng-template #loading>
	<!-- loading... -->
</ng-template>

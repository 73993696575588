import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlbumComponent } from '../../../modals/album/album.component';

@Component({
	selector: 'app-post-gallery-block',
	templateUrl: './post-gallery-block.component.html',
	styleUrls: ['./post-gallery-block.component.scss']
})
export class PostGalleryBlockComponent implements OnInit {
	@Input()
	public content: any;

	constructor(private _modal: NgbModal) {}

	public openAlbum(images): Promise<any> {
		const modal = this._modal.open(AlbumComponent, { windowClass: 'album-modal', centered: true });
		modal.componentInstance.images = images;
		modal.componentInstance.type = 'post_gallery';
		return modal.result;
	}

	ngOnInit() {}
}

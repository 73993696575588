<div class="row">
	<div class="col-md-4 pl-0">
		<a *ngIf="program.broadcast" routerLink="/adasok/{{ program.broadcast.category.slug }}/{{ program.broadcast.slug || '' }}">
			<img
				[src]=" program.featured_image || '/assets/img/street-default.png'"
				alt="{{ program.title }}"
				class="img-fluid" />
		</a>
		<img
			*ngIf="!program.broadcast"
			[src]=" program.featured_image || '/assets/img/street-default.png'"
			alt="{{ program.title }}"
			class="img-fluid" />
	</div>
	<div class="col-md-5 middle">
		<div class="content">
			<div>
				<div class="tag" [ngClass]="{'bg-orange': program.category.slug === 'rallye', 'bg-purple': program.category.slug === 'street'}">#{{ program.category.slug || 'rallye' }}</div>
			</div>
			<h4 *ngIf="!program.broadcast">{{ program.title }}</h4>
			<a *ngIf="program.broadcast" routerLink="/adasok/{{ program.broadcast.category.slug }}/{{ program.broadcast.slug || '' }}">
				<h4>{{ program.title }}</h4>
			</a>
			<p class="description" [innerHTML]="program.content || program.broadcast?.lead || ''"></p>
		</div>
	</div>
	<div class="col-md-3 align-self-center text-center py-3 py-md-0">
		<p class="date">{{ program.program_date.day }}</p>
		<p class="time">{{ program.program_date.time }}</p>
		<a class="btn btn-program"
		   [ngClass]="{'bg-orange': program.category.slug === 'rallye', 'bg-purple': program.category.slug === 'street'}"
		   *ngIf="program.broadcast && program.broadcast.type === 'video'"
		   routerLink="/adasok/{{ program.broadcast.category.slug }}/{{ program.broadcast.slug || '' }}">MEGNÉZEM</a>
		<a class="btn btn-program"
		   [ngClass]="{'bg-orange': program.category.slug === 'rallye', 'bg-purple': program.category.slug === 'street'}"
		   *ngIf="program.broadcast && program.broadcast.type === 'podcast'"
		   routerLink="/adasok/{{ program.broadcast.category.slug }}/{{ program.broadcast.slug || '' }}">MEGHALLGATOM</a>
		<button class="btn btn-program bg-gray btn-disabled" *ngIf="!program.broadcast">HAMAROSAN</button>
	</div>
</div>

<div class="holder">
	<div class="aspect-ratio">
		<a routerLink="/hirek/{{ post.category.slug }}/{{ post.slug }}">
			<img [src]=" post.featured_image_thumbnail || post.featured_image || '/assets/img/street-default.png'" class="img-fluid small-thumbnail" alt="" />
		</a>
	</div>
	<div class="tag" [ngClass]="{'bg-orange': post.category.slug === 'rallye', 'bg-purple': post.category.slug === 'street'}">#{{ post.category.slug || 'rallye' }}</div>
	<div class="title eq">
		<a routerLink="/hirek/{{ post.category.slug }}/{{ post.slug }}">
			<h2>{{ post.title || '' }}</h2>
		</a>
	</div>
	<div class="date pb-2 mb-3"><small>{{ post.published_ago || post.publish_date }}</small></div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { AdAPIService } from '@app/cms/services/ad.service';
import { Ad, Ads } from '@app/cms';

@Component({
	selector: 'app-ad',
	templateUrl: './ad.component.html',
	styleUrls: ['./ad.component.scss']
})
export class AdComponent implements OnInit {
	@Input()
	public page: string;

	@Input()
	public location: string;

	public ad$: Observable<Ads>;

	constructor(private _adsApi: AdAPIService) {}

	ngOnInit() {
		this.ad$ = this._getAd();
	}

	private _getAd() {
		return this._adsApi.getAds(this.page);
	}
}
